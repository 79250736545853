import { Model } from "@nna/core";
import * as z from "zod";

import { statementLineTypeModelSchema } from "./statement-line.type";
import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../../entry.change-state";
import { EntryCommon } from "../../../entry.common";
import { operatingAccountModelSchema } from "../operating-account.model";

/** Zod Shape for {@link statementLineModelSchema} */
export const statementLineModelRawShape = {
	evolution: EntryCommon.percentageSchema,
	value: EntryCommon.amountSchema,
	type: statementLineTypeModelSchema,
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link StatementLineModel} */
export const statementLineModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(statementLineModelRawShape),
		{
			...statementLineModelRawShape,
			fkOperatingAccount: operatingAccountModelSchema.shape._id,
		},
	),
});
/** Generic operating account > statement line model */
export type StatementLineModel = z.infer<typeof statementLineModelSchema>;
