import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import { recipeDtoSchema } from "../../../recipe/dtos";
import { pillar2AffiliationModelSchema } from "../pillar2.affiliation.model";

/** Validation schema for {@link Pillar2AffiliationDto} */
export const pillar2AffiliationDtoSchema = z.object({
	...pillar2AffiliationModelSchema.shape,

	// TODO DUPLICATE_737: not v2 friendly (custom pivot ?)
	recipes: z
		.array(recipeDtoSchema)
		.describe("The recipes of the affiliation (with the entitled)"),
});
export type Pillar2AffiliationDto = z.infer<typeof pillar2AffiliationDtoSchema>;

/** Validation schema for {@link Pillar2AffiliationQueryDto} */
export const pillar2AffiliationQueryDtoSchema = createQueryObjectSchema(
	pillar2AffiliationDtoSchema,
	{ coerce: true, strict: true },
).strict();
export type Pillar2AffiliationQueryDto = z.infer<
	typeof pillar2AffiliationQueryDtoSchema
>;

/** Validation schema for {@link Pillar2AffiliationQueryResultsDto} */
export const pillar2AffiliationQueryResultsDtoSchema = createQueryResultsSchema(
	pillar2AffiliationDtoSchema,
);
export type Pillar2AffiliationQueryResultsDto = z.infer<
	typeof pillar2AffiliationQueryResultsDtoSchema
>;
