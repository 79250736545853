import * as z from "zod";

import { createMultilineSchema } from "../../../common/multiline";
import { paymentReleaseSchema } from "../../common/payment-release";
import { pillar3YieldSchema } from "../../common/yields";
import { rawShape } from "../model";

/** Validation schema for {@link CreateDto} */
export const createDtoSchema = z.object({
	...rawShape,
	yields: createMultilineSchema(
		pillar3YieldSchema,
		pillar3YieldSchema
			.pick({ dateStart: true, percentage: true })
			.partial(),
	).default([]),
	paymentsRelease: createMultilineSchema(
		paymentReleaseSchema,
		paymentReleaseSchema.partial(),
	).default([]),
});
export type CreateDto = z.infer<typeof createDtoSchema>;

/** Validation schema for {@link UpdateDto} */
export const updateDtoSchema = createDtoSchema.partial();
export type UpdateDto = z.infer<typeof updateDtoSchema>;
