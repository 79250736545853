import * as z from "zod";

import { Options } from "./group.options";

const groups = [
	Options.AccountClosure,
	Options.AccountFund,
	Options.CreditClosure,
	Options.CreditEvolution,
	Options.LoanClosure,
	Options.LoanEvolution,
	Options.Pillar2AffiliationPlanClosure,
	Options.Pillar2VestedBenefitsFunding,
	Options.Pillar3AClosure,
	Options.Pillar3BClosure,
	Options.StockClosure,
	Options.StockFunding,
	Options.StockTransaction,
	Options.TransferableClosure,
	Options.VestedBenefitClosure,
	// TODO: more groups
] as const satisfies Array<{
	dtoSchema: z.ZodType<Options.BaseModel<string>>;
	schema: z.ZodType<Options.BaseModel<string>>;
}>;
const mapInSchemas = <T>(fn: (group: (typeof groups)[number]) => T) =>
	// Small hack to indicate to zod that the array is not empty
	groups.map(fn) as [T, ...T[]];

/** Validation schema for {@link Model} */
export const modelSchema = z.discriminatedUnion(
	Options.DISCRIMINATION_KEY,
	mapInSchemas(({ schema }) => schema),
);
/**
 * A (movement) group is a model that, alone, does not generate movements,
 * but may, for calculations, generate many "virtual" movements with shared properties or precedence
 * ex: Same movement from multiple sources, at a same date OR the movements increase through time
 */
export type Model = z.infer<typeof modelSchema>;

/** Known discriminated type for {@link Model} */
export type Type = Model[typeof Options.DISCRIMINATION_KEY];
/** List of available types for {@link Model} */
export const TYPES: readonly Type[] = modelSchema.options.map(
	({ shape }) => shape[Options.DISCRIMINATION_KEY].value,
);

/** Validation schema for {@link Dto} */
export const dtoSchema = z.discriminatedUnion(
	Options.DISCRIMINATION_KEY,
	mapInSchemas(
		({ dtoSchema }) =>
			// "Cast-hack" to avoid TS7056 (maximum length the compiler will serialize)
			dtoSchema as z.ZodObject<
				{ type: (typeof dtoSchema)["shape"]["type"] },
				z.UnknownKeysParam,
				z.ZodTypeAny,
				z.infer<typeof dtoSchema>
			>,
	),
);
/**
 * Dto extension of {@link Model}.
 * Principally for query/filter purposes.
 */
export type Dto = z.infer<typeof dtoSchema>;
