import { Model } from "@nna/core";
import * as z from "zod";

import { yearCappedSchema } from "../../common";
import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../entry.change-state";
import { independentModelSchema } from "../independent.model";

/** Zod Shape for {@link operatingAccountModelSchema} */
export const operatingAccountModelRawShape = {
	year: yearCappedSchema({}),
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link OperatingAccountModel} */
export const operatingAccountModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(operatingAccountModelRawShape),
		{
			...operatingAccountModelRawShape,
			fkIndependent: independentModelSchema.shape._id,
		},
	),
});

/** Operating account model (1st level) */
export type OperatingAccountModel = z.infer<typeof operatingAccountModelSchema>;
