import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import { dtoSchema as premiumDtoSchema } from "../../common/premium/dtos/dtos";
import { yieldDtoSchema } from "../../common/yields/dtos";
import { dtoSchema as closureDtoSchema } from "../closure/dtos/dtos";
import { modelSchema } from "../model";
import { paymentReleaseModelSchema } from "../payment-release/model";

/** Base dto for {@link dtoSchema} with limited relations */
export const dtoBaseSchema = z.object({
	...modelSchema.shape,
	paymentsRelease: z.array(z.lazy(() => paymentReleaseModelSchema)),
	yields: z.array(z.lazy(() => yieldDtoSchema)),
});

/** Validation schema for {@link Dto} */
export const dtoSchema = z.object({
	...dtoBaseSchema.shape,
	closure: z
		.lazy(() => closureDtoSchema)
		.nullable()
		.describe("The closure of this pillar.<br>`null` when there is none"),
	premiums: z.array(z.lazy(() => premiumDtoSchema)),
});
export type Dto = z.infer<typeof dtoSchema>;

/** Validation schema for {@link QueryDto} */
export const queryDtoSchema = createQueryObjectSchema(dtoSchema, {
	coerce: true,
	strict: true,
}).strict();
export type QueryDto = z.infer<typeof queryDtoSchema>;

/** Validation schema for {@link QueryResultsDto} */
export const queryResultsDtoSchema = createQueryResultsSchema(dtoSchema);
export type QueryResultsDto = z.infer<typeof queryResultsDtoSchema>;
