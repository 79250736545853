import { HttpRoute } from "@nna/core";

import { type Pillar2AffiliationPlanHttpConfig } from "../pillar2.affiliation-plan.http";
import type {
	Pillar2AffiliationPlanClosureCreateDto,
	Pillar2AffiliationPlanClosureDto,
	Pillar2AffiliationPlanClosureUpdateDto,
} from "./dtos";

/**
 * Create routes definitions for `Pillar2AffiliationPlanClosure` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createPillar2AffiliationPlanClosureHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const baseById = base.addSegment({
		param: "closureId",
		type: "param",
		validation: "number",
	});

	return {
		children: {},
		routes: {
			create: base.post<
				(
					body: Pillar2AffiliationPlanClosureCreateDto,
				) => Promise<Pillar2AffiliationPlanClosureDto>
			>(),
			delete: baseById.delete<
				() => Promise<Pillar2AffiliationPlanClosureDto>
			>(),
			update: baseById.patch<
				(
					body: Pillar2AffiliationPlanClosureUpdateDto,
				) => Promise<Pillar2AffiliationPlanClosureDto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

/** Full configuration for `Entry/Pillar2/Affiliation/Plan` features and children */
export type Pillar2AffiliationPlanClosureHttpConfig =
	Pillar2AffiliationPlanHttpConfig["children"]["closures"];
/** HTTP specification for the `Entry/Pillar2/Affiliation/Plan` features */
export type Pillar2AffiliationPlanClosureHttp = HttpRoute.Handlers<
	Pillar2AffiliationPlanClosureHttpConfig["routes"]
>;
