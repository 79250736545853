import { Model } from "@nna/core";
import * as z from "zod";

import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../entry.change-state";
import { independentModelSchema } from "../independent.model";
import { balanceLineTypeModelSchema } from "./balance-line.type";

/** Zod Shape for {@link balanceLineModelSchema} */
export const balanceLineModelRawShape = {
	valueAccounting: z.number(),
	valueReal: z.number(),
	type: balanceLineTypeModelSchema,
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link BalanceLineModel} */
export const balanceLineModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(balanceLineModelRawShape),
		{
			...balanceLineModelRawShape,
			fkIndependent: independentModelSchema.shape._id,
		},
	),
});
/** Generic balance sheet line model */
export type BalanceLineModel = z.infer<typeof balanceLineModelSchema>;
