import { Model } from "@nna/core";
import * as z from "zod";

import { dateCappedSchema } from "../../common/date";
import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../entry.change-state";
import { EntryCommon } from "../../entry.common";
import { stockModelSchema } from "../stock.model";

export const stockEvolutionTypeSchema = z.enum(["GAIN", "LOSS"]);
export type StockEvolutionType = z.infer<typeof stockEvolutionTypeSchema>;

export const stockValueEvolutionRawShape = {
	date: dateCappedSchema({}),
	evolution: EntryCommon.Evolution.schema.describe("Amount or Percent"),
	type: stockEvolutionTypeSchema,
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link StockModel} */
export const stockValueEvolutionModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(stockValueEvolutionRawShape),
		{
			...stockValueEvolutionRawShape,
			fkStock: stockModelSchema.shape._id,
		},
	),
});

export type StockValueEvolutionModel = z.infer<
	typeof stockValueEvolutionModelSchema
>;
