import { z } from "zod";

import { paymentReleaseRawShape, paymentReleaseSchema } from "./model";

export const paymentReleaseDtoSchema = paymentReleaseSchema;
export type PaymentReleaseDto = z.infer<typeof paymentReleaseDtoSchema>;

export const paymentReleaseCreateDtoSchema = z.object(paymentReleaseRawShape);
export type PaymentReleaseCreateDto = z.infer<
	typeof paymentReleaseCreateDtoSchema
>;

export const paymentReleaseUpdateDtoSchema =
	paymentReleaseCreateDtoSchema.partial();
export type PaymentReleaseUpdateDto = z.infer<
	typeof paymentReleaseUpdateDtoSchema
>;
