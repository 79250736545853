import { z } from "zod";

import * as PaymentRelease from "../../common/payment-release";
import { modelSchema } from "../model";

export const rawShape = {
	fkPillar3A: modelSchema.shape._id,
};

export const paymentReleaseSchema =
	PaymentRelease.paymentReleaseSchema.extend(rawShape);

export const paymentReleaseModelSchema =
	PaymentRelease.paymentReleaseModelSchema.extend(rawShape);

export type PaymentReleaseModel = z.infer<typeof paymentReleaseModelSchema>;
