import * as z from "zod";

import * as Retirement from "./retirement";
import { createClosureModelSchema } from "../../../common";
import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
	createChangeStateSchemaWithShapeFieldsV0,
} from "../../../entry.change-state";
import { Pillar2AffiliationPlanClosureType } from "../../../pillar2";

/** Discriminant value for {@link ModelBase} */
export const CLOSURE_TYPE =
	"CLOSURE" satisfies Pillar2AffiliationPlanClosureType;
/** Discriminant value for {@link ModelBase} */
export const RETIREMENT_TYPE =
	"RETIREMENT" satisfies Pillar2AffiliationPlanClosureType;

const {
	shape: { changeState: _, ...commonClosureShape },
} = createClosureModelSchema({}, {});

/** @internal */
const closureBaseShape = {
	type: z.literal(CLOSURE_TYPE),
} as const satisfies z.ZodRawShape;

/** @internal */
const retirementBaseShape = {
	payment: Retirement.paymentSchema,
	type: z.literal(RETIREMENT_TYPE),
} as const satisfies z.ZodRawShape;
/** @internal */
const retirementChangeState = createChangeStateSchemaWithShapeFieldsV0(
	retirementBaseShape,
	[
		"payment.amount",
		"payment.annuity.dateEnd",
		"payment.annuity.dateStart",
		"payment.annuity.type",
		"payment.percentage",
		"payment.type",
		"type",
	],
);
/** @internal */
const retirementSchema = z.object(
	addChangeStateSchemaToShape(retirementChangeState, {
		...commonClosureShape,
		...retirementBaseShape,
	}),
);

/** @internal */
function createModelSchemas<const S extends z.ZodRawShape>(closureShape: S) {
	// Create the 2 individuals schema for a closure

	const shape = { ...closureShape, ...closureBaseShape } as const;
	const closureChangeState = createChangeStateSchemaFromShapeV0(shape);
	const closureSchema = z.object(
		addChangeStateSchemaToShape(closureChangeState, {
			...commonClosureShape,
			...shape,
		}),
	);

	return { closureSchema, retirementSchema };
}
/**
 * Creates the model schema for a pillar3 closure (& retirement)
 *
 * @param closureShape additional shape for the closure-type of the closure
 * @returns validation schemas for a closure (both closure & retirement)
 */
export function createModelSchema<const S extends z.ZodRawShape>(
	closureShape: S,
) {
	const { closureSchema, retirementSchema } =
		createModelSchemas(closureShape);
	const modelSchema = z.discriminatedUnion("type", [
		closureSchema,
		retirementSchema,
	]);

	return {
		modelClosureSchema: closureSchema,
		modelRetirementSchema: retirementSchema,
		modelSchema,
	};
}

/**
 * Creates a validation schema for a pillar3 closure (& retirement).
 * Without `id` or dates.
 *
 * @param closure additional shape for the closure-type of the closure
 * @param commonShape additional shape added to both types of closure
 * @returns validation schema for a closure (both closure & retirement)
 */
export function createValidationSchema<
	const S extends z.ZodRawShape,
	const S_COMMON extends z.ZodRawShape,
>(closure: S, commonShape: S_COMMON) {
	const { closureSchema, retirementSchema } = createModelSchemas(closure);

	const {
		_id: _c0,
		changeState: _c3,
		createdAt: _c1,
		updatedAt: _c2,
		...closureShape
	} = closureSchema.shape;
	const {
		_id: _r0,
		changeState: _r3,
		createdAt: _r1,
		updatedAt: _r2,
		...retirementShape
	} = retirementSchema.shape;

	return z.discriminatedUnion("type", [
		z.object({ ...closureShape, ...commonShape } as const),
		z.object({ ...retirementShape, ...commonShape } as const),
	]);
}

/** Available {@link Type} for a closure */
export const TYPES = createModelSchema({}).modelSchema.options.map(
	({ shape }) => shape.type.value,
);
export type Type = (typeof TYPES)[number];
