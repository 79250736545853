import { Model } from "@nna/core";
import * as z from "zod";

import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../../../entry.change-state";
import { EntryCommon } from "../../../../entry.common";
import { characteristicsModelSchema } from "../characteristics.model";

/** Zod Shape for {@link characteristicsContributionModelSchema} */
export const characteristicsContributionModelRawShape = {
	age: z.number().int().gte(17).lte(200),
	savingMale: EntryCommon.boundedPercentageSchema,
	riskMale: EntryCommon.boundedPercentageSchema,
	savingFemale: EntryCommon.boundedPercentageSchema,
	riskFemale: EntryCommon.boundedPercentageSchema,
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link CharacteristicsContributionModel} */
export const characteristicsContributionModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(
			characteristicsContributionModelRawShape,
		),
		{
			...characteristicsContributionModelRawShape,
			fkCharacteristics: characteristicsModelSchema.shape._id,
		},
	),
});

export type CharacteristicsContributionModel = z.infer<
	typeof characteristicsContributionModelSchema
>;
