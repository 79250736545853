import { HttpRoute } from "@nna/core";

import type * as DTOs from "./dtos";
import { type Pillar2AffiliationHttpConfig } from "../pillar2.affiliation.http";
import { createPillar2AffiliationPlanClosureHttpDefinitions } from "./closure/pillar2.affiliation-plan-closure.http";

/**
 * Create routes definitions for `Pillar2/Affiliation/Plan` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createPillar2AffiliationPlanHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const baseById = base.addSegment({
		param: "pillar2AffiliationPlanId",
		type: "param",
		validation: "number",
	});

	return {
		children: {
			closures: createPillar2AffiliationPlanClosureHttpDefinitions(
				baseById.addSegment("closures"),
			),
		},
		routes: {
			findAndCount:
				base.get<
					(
						query: DTOs.Pillar2AffiliationPlanQueryDto,
					) => Promise<DTOs.Pillar2AffiliationPlanQueryResultsDto>
				>(),
			findById:
				baseById.get<() => Promise<DTOs.Pillar2AffiliationPlanDto>>(),

			create: base.post<
				(
					body: DTOs.Pillar2AffiliationPlanCreateDto,
				) => Promise<DTOs.Pillar2AffiliationPlanDto>
			>(),
			delete: baseById.delete<
				() => Promise<DTOs.Pillar2AffiliationPlanDto>
			>(),
			update: baseById.patch<
				(
					body: DTOs.Pillar2AffiliationPlanUpdateDto,
				) => Promise<DTOs.Pillar2AffiliationPlanDto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

/** Full configuration for `Entry/Pillar2/Affiliation/Plan` features and children */
export type Pillar2AffiliationPlanHttpConfig =
	Pillar2AffiliationHttpConfig["children"]["plans"];
/** HTTP specification for the `Entry/Pillar2/Affiliation/Plan` features */
export type Pillar2AffiliationPlanHttp = HttpRoute.Handlers<
	Pillar2AffiliationPlanHttpConfig["routes"]
>;
