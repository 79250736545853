import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import {
	statementLineModelRawShape,
	statementLineModelSchema,
} from "./statement-line.model";

/** Validation schema for {@link StatementLineDto} */
export const statementLineDtoSchema = z.object({
	...statementLineModelSchema.shape,
});
export type StatementLineDto = z.infer<typeof statementLineDtoSchema>;

/** Validation schema for {@link StatementLineCreateDto} */
export const statementLineCreateDtoSchema = z.object(
	statementLineModelRawShape,
);
export type StatementLineCreateDto = z.infer<
	typeof statementLineCreateDtoSchema
>;

/** Validation schema for {@link StatementLineUpdateDto} */
export const statementLineUpdateDtoSchema =
	statementLineCreateDtoSchema.partial();
export type StatementLineUpdateDto = z.infer<
	typeof statementLineUpdateDtoSchema
>;

/** Validation schema for {@link StatementLineQueryDto} */
export const statementLineQueryDtoSchema = createQueryObjectSchema(
	statementLineDtoSchema,
	{ coerce: true, strict: true },
).strict();
export type StatementLineQueryDto = z.infer<typeof statementLineQueryDtoSchema>;

/** Validation schema for {@link StatementLineQueryResultsDto} */
export const statementLineQueryResultsDtoSchema = createQueryResultsSchema(
	statementLineDtoSchema,
);
export type StatementLineQueryResultsDto = z.infer<
	typeof statementLineQueryResultsDtoSchema
>;
