import { createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import { vestedBenefitDtoSchema } from "./vested-benefits.dto";

/** Validation schema for {@link VestedBenefitsQueryResultsDto} */
export const vestedBenefitsQueryResultsDtoSchema = createQueryResultsSchema(
	vestedBenefitDtoSchema,
);
/** Results for `vestedBenefits` from a query */
export type VestedBenefitsQueryResultsDto = z.infer<
	typeof vestedBenefitsQueryResultsDtoSchema
>;
