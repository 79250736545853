import * as z from "zod";

import { sourceAmountActionsSchema } from "../../../../../source-amount/source-amount.actions";
import { pillar2AffiliationPlanClosureModelSchema } from "../pillar2.affiliation-plan-closure.model";

/** Validation schema for {@link Pillar2AffiliationPlanClosureCreateDto} */
export const pillar2AffiliationPlanClosureCreateDtoSchema = z.object({
	...pillar2AffiliationPlanClosureModelSchema.pick({
		annuity: true,
		capital: true,
		date: true,
		type: true,
	}).shape,
	funds: sourceAmountActionsSchema,
});
export type Pillar2AffiliationPlanClosureCreateDto = z.infer<
	typeof pillar2AffiliationPlanClosureCreateDtoSchema
>;

/** Validation schema for {@link Pillar2AffiliationPlanClosureUpdateDto} */
export const pillar2AffiliationPlanClosureUpdateDtoSchema =
	pillar2AffiliationPlanClosureCreateDtoSchema.partial();
export type Pillar2AffiliationPlanClosureUpdateDto = z.infer<
	typeof pillar2AffiliationPlanClosureUpdateDtoSchema
>;
