import { HttpRoute } from "@nna/core";

import {
	Pillar2PlanCreateDto,
	Pillar2PlanDto,
	Pillar2PlanQueryDto,
	Pillar2PlanQueryResultsDto,
	Pillar2PlanUpdateDto,
} from "./pillar2.plan.dto";
import type { Pillar2HttpConfig } from "../pillar2.http";
import { createCharacteristicsHttpDefinitions } from "./characteristics";

/**
 * Create routes definitions for `Pillar2/Plan` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createPillar2PlanHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const baseById = base.addSegment({
		param: "pillar2PlanId",
		type: "param",
		validation: "number",
	});

	return {
		children: {
			characteristics: createCharacteristicsHttpDefinitions(
				baseById.addSegment("characteristics"),
			),
		},
		routes: {
			findAndCount:
				base.get<
					(
						query: Pillar2PlanQueryDto,
					) => Promise<Pillar2PlanQueryResultsDto>
				>(),
			findById: baseById.get<() => Promise<Pillar2PlanDto>>(),

			create: base.post<
				(body: Pillar2PlanCreateDto) => Promise<Pillar2PlanDto>
			>(),
			delete: baseById.delete<() => Promise<Pillar2PlanDto>>(),
			update: baseById.patch<
				(body: Pillar2PlanUpdateDto) => Promise<Pillar2PlanDto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

/** Full configuration for `Entry/Pillar2/Plan` features and children */
export type Pillar2PlanHttpConfig = Pillar2HttpConfig["children"]["plans"];
/** HTTP specification for the `Entry/Pillar2/Plan` features */
export type Pillar2PlanHttp = HttpRoute.Handlers<
	Pillar2PlanHttpConfig["routes"]
>;
