import { Model } from "@nna/core";
import * as z from "zod";

import { dateCappedSchema } from "../common/date";
import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../entry.change-state";
import { EntryCommon } from "../entry.common";
import { entryModelSchema } from "../entry.model";

export const STOCK_AHEAD_TAXATION_ENABLED_KEY = "enabled";

// We cannot use booleans because MikroORM wants a number as discriminator
export const stockAheadTaxationDisabledSchema = z.object({
	[STOCK_AHEAD_TAXATION_ENABLED_KEY]: z.literal(0),
});
export const stockAheadTaxationEnabledSchema = z.object({
	[STOCK_AHEAD_TAXATION_ENABLED_KEY]: z.literal(1),
	yearsBeforeRecuperation: z.number().int().positive(),
	lastYearTaxesToRecuperate: EntryCommon.amountSchema,
	currentYearTaxesToRecuperate: EntryCommon.amountSchema,
});

export const stockAheadTaxationSchema = z.discriminatedUnion(
	STOCK_AHEAD_TAXATION_ENABLED_KEY,
	[stockAheadTaxationDisabledSchema, stockAheadTaxationEnabledSchema],
);

export type StockAheadTaxationEnabledModel = z.infer<
	typeof stockAheadTaxationEnabledSchema
>;
export type StockAheadTaxationDisabledModel = z.infer<
	typeof stockAheadTaxationDisabledSchema
>;
export type StockAheadTaxationModel = z.infer<typeof stockAheadTaxationSchema>;

/** Zod Shape for {@link stockModelSchema} */
export const stockModelRawShape = {
	beneficiary: EntryCommon.customerSingleSchema,

	description: z.string(),
	/** The identifier of the stock */
	stockNb: z.string().nullable(),

	depositoryCompany: z.string().nullable(),

	nbOfStock: EntryCommon.positiveAmountSchema,
	totalNbOfStock: EntryCommon.positiveAmountSchema.nullable(),

	qualifiedParticipation: z.boolean(),

	dividendPaymentDate: dateCappedSchema({}).nullable(),

	marketValue: EntryCommon.positiveAmountSchema,
	fiscalValue: EntryCommon.positiveAmountSchema,

	acquisitionDate: dateCappedSchema({})
		.nullable()
		.describe(
			"acquisition date, can be null if the action was purchased in the past (and the buy date therefore does not matter)",
		),

	aheadTaxation: stockAheadTaxationSchema,
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link StockModel} */
export const stockModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(stockModelRawShape),
		{
			...stockModelRawShape,
			fkEntry: entryModelSchema.shape._id,
		},
	),
});

export type StockModel = z.infer<typeof stockModelSchema>;
