import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import {
	characteristicsContributionModelRawShape,
	characteristicsContributionModelSchema,
} from "./characteristics.contribution.model";

/** Validation schema for {@link CharacteristicsContributionDto} */
export const characteristicsContributionDtoSchema =
	characteristicsContributionModelSchema;
export type CharacteristicsContributionDto = z.infer<
	typeof characteristicsContributionDtoSchema
>;

/** Validation schema for {@link CharacteristicsContributionCreateDto} */
export const characteristicsContributionCreateDtoSchema = z.object(
	characteristicsContributionModelRawShape,
);
/** Dto to create a `contribution` */
export type CharacteristicsContributionCreateDto = z.infer<
	typeof characteristicsContributionCreateDtoSchema
>;

/** Validation schema for {@link CharacteristicsContributionQueryDto} */
export const characteristicsContributionQueryDtoSchema =
	createQueryObjectSchema(characteristicsContributionDtoSchema, {
		coerce: true,
		strict: true,
	}).strict();

/** DTO used to filter `characteristicsContribution` */
export type CharacteristicsContributionQueryDto = z.infer<
	typeof characteristicsContributionQueryDtoSchema
>;

/** Validation schema for {@link CharacteristicsContributionQueryResultsDto} */
export const characteristicsContributionQueryResultsDtoSchema =
	createQueryResultsSchema(characteristicsContributionDtoSchema);
/** Results for `contribution` from a query */
export type CharacteristicsContributionQueryResultsDto = z.infer<
	typeof characteristicsContributionQueryResultsDtoSchema
>;

/** Validation schema for {@link CharacteristicsContributionUpdateDto} */
export const characteristicsContributionUpdateDtoSchema =
	characteristicsContributionCreateDtoSchema.partial();
/** Dto to update a `contribution` */
export type CharacteristicsContributionUpdateDto = z.infer<
	typeof characteristicsContributionUpdateDtoSchema
>;
