import { Model } from "@nna/core";
import * as z from "zod";

import { dateCappedSchema } from "../../common";
import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../entry.change-state";
import { EntryCommon } from "../../entry.common";
import { entryModelSchema } from "../../entry.model";
import { methodCalculationSchema } from "../common/method-calculus";

/** Validation schema for {@link Type} */
export const typeSchema = z.enum(["bank", "insurance"]);
export type Type = z.infer<typeof typeSchema>;

/** Zod Shape for {@link modelSchema} */
export const rawShape = {
	type: typeSchema,

	entitled: EntryCommon.customerSchema.describe(
		"The customer(s) that possess this pillar3A",
	),
	identifier: z.string().describe("Bank account number or assurance policy"),
	name: z.string().min(2).describe("Name of the account/establishment"),

	dateEnd: dateCappedSchema({}).describe(
		"End date with `assurance` type, unused with `bank`",
	),
	dateStart: dateCappedSchema({}).describe(
		"Start date with `assurance` type, 'conclusion' with `bank`",
	),

	// Behavior of the following slightly change depending on the date.
	amountInitial: EntryCommon.amountSchema.describe(
		"'valeur de rachat' with `assurance` type | 'solde' with `bank`",
	),

	capitalGuarantee: EntryCommon.amountSchema
		.nullable()
		.describe("Unused with `bank` type"),
	methodCalculation: methodCalculationSchema.describe(
		"Calculation method with `assurance`.<br>Unused with `bank` type",
	),
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link Model} */
export const modelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(rawShape),
		{
			...rawShape,
			fkEntry: entryModelSchema.shape._id,
		},
	),
});
export type Model = z.infer<typeof modelSchema>;
