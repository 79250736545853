import * as z from "zod";

import { vestedBenefitBaseDtoSchema } from "./vested-benefits-base.dto";
import { sourceAmountDtoSchema } from "../../../source-amount";
import { vestedBenefitClosureDtoSchema } from "../closure/dtos/vested-benefit-closure.dto";

/** Validation schema for {@link VestedBenefitsDto} */
export const vestedBenefitDtoSchema = z.object({
	...vestedBenefitBaseDtoSchema.shape,

	closure: z.lazy(() => vestedBenefitClosureDtoSchema).nullable(),
	funds: z.array(z.lazy(() => sourceAmountDtoSchema)),
});

/** Default Dto for a `vestedBenefit` */
export type VestedBenefitsDto = z.infer<typeof vestedBenefitDtoSchema>;
