import { Model } from "@nna/core";
import * as z from "zod";

import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../entry.change-state";
import { entryModelSchema } from "../../entry.model";

/** Zod Shape for {@link pillar2AffiliationModelSchema} */
export const pillar2AffiliationModelRawShape =
	{} as const satisfies z.ZodRawShape;

/** Validation schema for {@link RecipeModel} */
export const pillar2AffiliationModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(pillar2AffiliationModelRawShape),
		{
			...pillar2AffiliationModelRawShape,
			fkEntry: entryModelSchema.shape._id,
		},
	),
});
/** An affiliation links a recipe with "pillar2 plan configurations" */
export type Pillar2AffiliationModel = z.infer<
	typeof pillar2AffiliationModelSchema
>;
