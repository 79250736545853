import { z } from "zod";

import { pillar3YieldModelSchema } from "../../common";
import { modelSchema } from "../model";

export const pillar3BYieldModelSchema = pillar3YieldModelSchema.extend({
	fkPillar3B: modelSchema.shape._id,
});

export type YieldModel = z.infer<typeof pillar3BYieldModelSchema>;
