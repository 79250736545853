import * as z from "zod";

import { recipeModelSchema } from "../../../recipe";
import { pillar2AffiliationModelRawShape } from "../pillar2.affiliation.model";

/** Validation schema for {@link Pillar2AffiliationCreateDto} */
export const pillar2AffiliationCreateDtoSchema = z.object({
	...pillar2AffiliationModelRawShape,

	// TODO DUPLICATE_737: not v2 friendly (custom pivot ?)
	fkRecipes: z
		.array(recipeModelSchema.shape._id)
		.min(1)
		.describe(
			'"PUT" list of recipes this affiliation.<br />' +
				"On update with the value set, it removes the ids that are not present",
		),
});
export type Pillar2AffiliationCreateDto = z.infer<
	typeof pillar2AffiliationCreateDtoSchema
>;

/** Validation schema for {@link Pillar2AffiliationUpdateDto} */
export const pillar2AffiliationUpdateDtoSchema =
	pillar2AffiliationCreateDtoSchema.partial();
export type Pillar2AffiliationUpdateDto = z.infer<
	typeof pillar2AffiliationUpdateDtoSchema
>;
