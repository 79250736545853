import { ModelNumber } from "@nna/core";
import * as z from "zod";

import { stockDtoBaseSchema } from "../../../stock/stock-base.dto";
import { createSchema } from "../lib";

/** Unique type discrimination for {@link Model} */
export const TYPE = "stock-funding";

/** Validation schema for {@link Model} */
export const schema = createSchema(TYPE, {
	fkStock: ModelNumber.schema.shape._id, // Avoid cycles
});

export type Model = z.infer<typeof schema>;

/** Validation schema for {@link Dto} */
export const dtoSchema = z.object({
	...schema.shape,
	stock: z.lazy(() => stockDtoBaseSchema),
});
/**
 * Dto extension of {@link Model}.
 * Principally for query/filter purposes.
 */
export type Dto = z.infer<typeof dtoSchema>;
