import * as z from "zod";

import { accountMovementSchema } from "../../account/account.movement";
import { MovementData } from "../../movement";

/** Validation schema for {@link Pillar2VestedBeneftisMovementDirection} */
export const pillar2VestedBenefitsMovementDirectionSchema = z
	.enum([
		// For 'EPL' conversion
		"EPL_in", // "Remboursement"
		"EPL_out", // "Retrait"

		// For 'divorce-allowance' conversion
		"divorce-allowance_in", // "recue"
		"divorce-allowance_out", // "versée"
	])
	.describe(
		"Define the direction of the movement with a pillar2/vested-benefits",
	);
export type Pillar2VestedBenefitsMovementDirection = z.infer<
	typeof pillar2VestedBenefitsMovementDirectionSchema
>;

/** Schema for {@link Pillar2VestedBenefitsMovement} */
export const pillar2VestedBenefitsMovementSchema = z.object({
	...accountMovementSchema.shape,
	direction: pillar2VestedBenefitsMovementDirectionSchema,
});
/** To manipulate movement from or out of a pillar2/vested-benefits */
export type Pillar2VestedBenefitsMovement = z.infer<
	typeof pillar2VestedBenefitsMovementSchema
>;

/** Output of {@link Pillar2VestedBenefitsMovementDirectionInfo} */
export interface Pillar2VestedBenefitsMovementDirectionInfo {
	/** From the direction, is the 'VestedBenefits' the input (`to` property) */
	asInput: boolean;
	/** The conversions */
	kind: MovementData.Options.Pillar2VestedBenefits.Kind;
}
/** Get "movement" information from {@link Pillar2VestedBenefitsMovementDirection} */
export function getPillar2VestedBenefitsMovementDirectionInfo(
	direction: Pillar2VestedBenefitsMovementDirection,
): Pillar2VestedBenefitsMovementDirectionInfo {
	// Note: this could be replaced by a "convert-object"

	switch (direction) {
		case "EPL_in":
			return { asInput: true, kind: "EPL" };
		case "EPL_out":
			return { asInput: false, kind: "EPL" };

		case "divorce-allowance_in":
			return { asInput: true, kind: "divorce-allowance" };
		case "divorce-allowance_out":
			return { asInput: false, kind: "divorce-allowance" };
	}
}
/**
 * Opposite of {@link Pillar2VestedBenefitsMovementDirectionInfo}:
 * Get the direction from info
 */
export function getPillar2VestedBenefitsMovementDirectionFromInfo(
	info: Pillar2VestedBenefitsMovementDirectionInfo,
): Pillar2VestedBenefitsMovementDirection {
	const { asInput, kind } = info;
	switch (kind) {
		case "EPL":
			return asInput ? "EPL_in" : "EPL_out";
		case "divorce-allowance":
			return asInput ? "divorce-allowance_in" : "divorce-allowance_out";
	}
}
