import { ModelNumber } from "@nna/core";
import * as z from "zod";

import { vestedBenefitBaseDtoSchema } from "../../pillar2/vested-benefits/dtos/vested-benefits-base.dto";
import { createSchema } from "../lib";

/** Unique type discrimination for {@link Model} */
export const TYPE = "pillar2-vested-benefits";

/** Validation schema for {@link Model} */
export const schema = createSchema(TYPE, {
	fkPillar2VestedBenefits: ModelNumber.schema.shape._id,
});
/** Movement source for an {@link vestedBenefitsDtoBaseSchema} */
export type Model = z.infer<typeof schema>;

/** Validation schema for {@link Dto} */
export const dtoSchema = z.object({
	...schema.shape,
	pillar2VestedBenefits: z.lazy(() => vestedBenefitBaseDtoSchema),
});
/**
 * Dto extension of {@link Model}.
 * Principally for query/filter purposes.
 */
export type Dto = z.infer<typeof dtoSchema>;
