import { Model } from "@nna/core";
import * as z from "zod";

import { dateCappedSchema } from "../../../common";
import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../../entry.change-state";
import { EntryCommon } from "../../../entry.common";
import { sourceSchema } from "../../../source/source.model";

/** Validation schema for */
export const periodicitySchema = z.enum([
	"punctual",
	"monthly",
	"quarterly",
	"semestrial",
	"annual",
]);
export type Periodicity = z.infer<typeof periodicitySchema>;

/** Zod Shape for {@link modelSchema} */
export const rawShape = {
	amountRisk: EntryCommon.positiveAmountSchema,
	amountSavings: EntryCommon.positiveAmountSchema,

	dateStart: dateCappedSchema({}),
	dateEnd: dateCappedSchema({}).describe(
		"Unused when the periodicity is 'punctual'",
	),
	periodicity: periodicitySchema,
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link Model} */
export const modelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(rawShape),
		// source is not supposed to change after creation
		{ ...rawShape, source: z.lazy(() => sourceSchema) },
	),
});
export type Model = z.infer<typeof modelSchema>;
