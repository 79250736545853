import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import { sourceDtoSchema } from "../../../../source/source.model";
import { Model, modelSchema } from "../model";

/** Validation schema for {@link Dto} */
export const dtoSchema = z.object({
	...modelSchema.shape,
	source: z.lazy(() => sourceDtoSchema),
} satisfies Record<keyof Model, z.ZodTypeAny>);
export type Dto = z.infer<typeof dtoSchema>;

/** Validation schema for {@link QueryDto} */
export const queryDtoSchema = createQueryObjectSchema(dtoSchema, {
	coerce: true,
	strict: true,
}).strict();
export type QueryDto = z.infer<typeof queryDtoSchema>;

/** Validation schema for {@link QueryResultsDto} */
export const queryResultsDtoSchema = createQueryResultsSchema(dtoSchema);
export type QueryResultsDto = z.infer<typeof queryResultsDtoSchema>;
