import { Entry } from "../../../..";

type StatementLineTypeLimited = Partial<
	Pick<Entry.StatementLineDto, "type" | "value">
>;

/**
 * Helper function that calculate the total revenue from an income statement
 */
export function getStatementLineTypeTotal(
	statementLines: StatementLineTypeLimited[],
	typeToFilter: Entry.StatementLineTypeType,
) {
	return statementLines
		.filter(({ type }) => type && type.type === typeToFilter)
		.reduce((acc, statementLine) => acc + (statementLine.value ?? 0), 0);
}

/**
 * Helper function that calculate the gross profit from an income statement
 */
export function getGrossProfit(statementLines: StatementLineTypeLimited[]) {
	return (
		getStatementLineTypeTotal(statementLines, "revenue") -
		getStatementLineTypeTotal(statementLines, "cost-revenue")
	);
}

/**
 * Helper function that calculate the financial profit from an income statement
 */
export function getFinancialProfit(statementLines: StatementLineTypeLimited[]) {
	return (
		getGrossProfit(statementLines) -
		getStatementLineTypeTotal(statementLines, "expenses-hr") -
		getStatementLineTypeTotal(statementLines, "expenses-other")
	);
}

/**
 * Helper function that calculate the operating profit from an income statement
 */
export function getOperatingProfit(statementLines: StatementLineTypeLimited[]) {
	return (
		getFinancialProfit(statementLines) -
		getStatementLineTypeTotal(statementLines, "expenses-financial")
	);
}
