import { HttpRoute } from "@nna/core";

import { createHttpDefinitions as createClosureHttpDefinitions } from "./closure/http";
import type * as DTOs from "./dtos";
import { createHttpDefinitions as createPremiumHttpDefinitions } from "./premium/http";
import { type EntryHttpConfig } from "../../entry.http";

/**
 * Create routes definitions for `Pillar3/A` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createHttpDefinitions<const BASE extends HttpRoute.BuilderAny>(
	base: BASE,
) {
	const baseById = base.addSegment({
		param: "pillar3AId",
		type: "param",
		validation: "number",
	});

	return {
		children: {
			closures: createClosureHttpDefinitions(
				baseById.addSegment("closures"),
			),
			premiums: createPremiumHttpDefinitions(
				baseById.addSegment("premiums"),
			),
		},
		routes: {
			findAndCount:
				base.get<
					(query: DTOs.QueryDto) => Promise<DTOs.QueryResultsDto>
				>(),
			findById: baseById.get<() => Promise<DTOs.Dto>>(),

			create: base.post<(body: DTOs.CreateDto) => Promise<DTOs.Dto>>(),
			delete: baseById.delete<() => Promise<DTOs.Dto>>(),
			update: baseById.patch<
				(body: DTOs.UpdateDto) => Promise<DTOs.Dto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

/** Full configuration for `Entry/Pillar3/A` features and children */
export type HttpConfig = EntryHttpConfig["children"]["pillar3"]["a"];
/** HTTP specification for the `Entry/Pillar3/A` features */
export type Http = HttpRoute.Handlers<HttpConfig["routes"]>;
