import * as z from "zod";

import { createMultilineSchema } from "../../../../common/multiline";
import { pillar2AffiliationPlanModelRawShape } from "../pillar2.affiliation-plan.model";
import { pillar2AffiliationPlanMovementSchema } from "../pillar2.affiliation-plan.movement";

const {
	direction: _1,
	source: _2,
	...updateMovementShape
} = pillar2AffiliationPlanMovementSchema.shape;

/** Validation schema for {@link Pillar2AffiliationPlanCreateDto} */
export const pillar2AffiliationPlanCreateDtoSchema = z.object({
	...pillar2AffiliationPlanModelRawShape,
	movements: createMultilineSchema(
		pillar2AffiliationPlanMovementSchema,
		z.object(updateMovementShape).partial(),
	).default([]),
});
export type Pillar2AffiliationPlanCreateDto = z.infer<
	typeof pillar2AffiliationPlanCreateDtoSchema
>;

/** Validation schema for {@link Pillar2AffiliationPlanUpdateDto} */
export const pillar2AffiliationPlanUpdateDtoSchema =
	pillar2AffiliationPlanCreateDtoSchema.partial();
export type Pillar2AffiliationPlanUpdateDto = z.infer<
	typeof pillar2AffiliationPlanUpdateDtoSchema
>;
