import { Model } from "@nna/core";
import * as z from "zod";

import { dateCappedSchema } from "../../../common";
import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../../entry.change-state";
import { EntryCommon } from "../../../entry.common";

export const pillar3YieldRawShape = {
	dateStart: dateCappedSchema({}).describe("Start date of yield"),
	percentage: EntryCommon.percentageSchema,
};

export const pillar3YieldSchema = z.object(pillar3YieldRawShape);
export type Pillar3Yield = z.infer<typeof pillar3YieldSchema>;

export const pillar3YieldModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(pillar3YieldRawShape),
		{
			...pillar3YieldRawShape,
		},
	),
});

export type Pillar3YieldModel = z.infer<typeof pillar3YieldModelSchema>;
