import * as z from "zod";

import { EntryCommon } from "../../../../entry.common";
import { pillar2AffiliationPlanModelSchema } from "../pillar2.affiliation-plan.model";

/** Validation schema for {@link Pillar2AffiliationPlanClosureType} */
export const pillar2AffiliationPlanClosureTypeSchema = z.enum([
	"CLOSURE",
	"RETIREMENT",
]);
export type Pillar2AffiliationPlanClosureType = z.infer<
	typeof pillar2AffiliationPlanClosureTypeSchema
>;

/** Validation schema for {@link Pillar2AffiliationPlanClosureModel} */
export const pillar2AffiliationPlanClosureModelSchema =
	EntryCommon.createClosureModelSchema(
		{
			type: pillar2AffiliationPlanClosureTypeSchema,

			// Not use with `closure`
			annuity: EntryCommon.boundedPercentageSchema,
			capital: EntryCommon.boundedPercentageSchema,
		},
		{ fkPlan: pillar2AffiliationPlanModelSchema.shape._id },
	);
/** Closure of `pillar2/affiliation/plan`. It can also be a retirement */
export type Pillar2AffiliationPlanClosureModel = z.infer<
	typeof pillar2AffiliationPlanClosureModelSchema
>;
