import { HttpRoute } from "@nna/core";

import { Pillar2PlanHttpConfig } from "../pillar2.plan.http";
import {
	CharacteristicsCreateDto,
	CharacteristicsDto,
	CharacteristicsQueryDto,
	CharacteristicsQueryResultsDto,
	CharacteristicsUpdateDto,
} from "./characteristics.dto";

/**
 * Create routes definitions for `Pillar2/Plan/Characteristics` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createCharacteristicsHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const baseById = base.addSegment({
		param: "characteristicsId",
		type: "param",
		validation: "number",
	});

	return {
		children: {},
		routes: {
			findAndCount:
				base.get<
					(
						query: CharacteristicsQueryDto,
					) => Promise<CharacteristicsQueryResultsDto>
				>(),
			findById: baseById.get<() => Promise<CharacteristicsDto>>(),

			create: base.post<
				(body: CharacteristicsCreateDto) => Promise<CharacteristicsDto>
			>(),
			delete: baseById.delete<() => Promise<CharacteristicsDto>>(),
			update: baseById.patch<
				(body: CharacteristicsUpdateDto) => Promise<CharacteristicsDto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

/** Full configuration for `Entry/Pillar2/Plan/Characteristics` features and children */
export type CharacteristicsHttpConfig =
	Pillar2PlanHttpConfig["children"]["characteristics"];
/** HTTP specification for the `Entry/Pillar2/Plan/Characteristics` features */
export type CharacteristicsHttp = HttpRoute.Handlers<
	CharacteristicsHttpConfig["routes"]
>;
