import * as z from "zod";

import { createMultilineSchema } from "../../../common/multiline";
import { sourceAmountActionsSchema } from "../../../source-amount";
import { vestedBenefitsModelRawShape } from "../vested-benefits.model";
import { pillar2VestedBenefitsMovementSchema } from "../vested-benefits.movement";

/** Validation schema for {@link VestedBenefitsCreateDto} */
export const vestedBenefitsCreateDtoSchema = z
	.object({
		...vestedBenefitsModelRawShape,
		funds: z.lazy(() => sourceAmountActionsSchema),
		movements: createMultilineSchema(
			pillar2VestedBenefitsMovementSchema,
			pillar2VestedBenefitsMovementSchema
				.pick({ amount: true, date: true })
				.partial(),
		).default([]),
	})
	.strict();

/** Dto to create a `vestedBenefit` */
export type VestedBenefitsCreateDto = z.infer<
	typeof vestedBenefitsCreateDtoSchema
>;
