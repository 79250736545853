import * as z from "zod";

/** Validation schema for {@link MethodCalculation} */
export const methodCalculationSchema = z.enum([
	// 'capital garanti'
	"capital",
	// 'rendement projeté'
	"yield",
]);
export type MethodCalculation = z.infer<typeof methodCalculationSchema>;
