import * as z from "zod";

import { dateCappedSchema } from "../../../common";
import { EntryCommon } from "../../../entry.common";

/** Validation schema for {@link Annuity} */
export const annuitySchema = z.discriminatedUnion("type", [
	z.object({ type: z.literal("life").describe('"Viagère"') }),
	z.object({
		type: z.literal("temporary").describe('"Temporaire"'),

		dateStart: dateCappedSchema({}),
		dateEnd: dateCappedSchema({}),
	}),
]);
export type Annuity = z.infer<typeof annuitySchema>;
export type AnnuityLife = Extract<Annuity, { type: "life" }>;
export type AnnuityTemporary = Extract<Annuity, { type: "temporary" }>;

/** Available {@link AnnuityType} */
export const ANNUITY_TYPES = annuitySchema.options.map(
	({ shape }) => shape.type.value,
);
export type AnnuityType = (typeof ANNUITY_TYPES)[number];

/** Validation schema for {@link Payment} */
export const paymentSchema = z.discriminatedUnion("type", [
	z.object({
		type: z.literal("annuity").describe('"Rente"'),

		amount: EntryCommon.amountSchema.describe('"Montant de la rente"'),
		annuity: annuitySchema,
		percentage: EntryCommon.boundedPercentageSchema.describe(
			'"Pourcentage imposable de la rente"',
		),
	}),
	z.object({
		type: z.literal("capital").describe('"Versement capital"'),
	}),
]);
export type Payment = z.infer<typeof paymentSchema>;
export type PaymentAnnuity = Extract<Payment, { type: "annuity" }>;
export type PaymentCapital = Extract<Payment, { type: "capital" }>;

/** Available {@link PaymentType} */
export const PAYMENT_TYPES = paymentSchema.options.map(
	({ shape }) => shape.type.value,
);
export type PaymentType = (typeof PAYMENT_TYPES)[number];
