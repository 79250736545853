import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import {
	balanceLineCreateDtoSchema,
	balanceLineDtoSchema,
	balanceLineUpdateDtoSchema,
} from "./balance-line";
import {
	independentModelRawShape,
	independentModelSchema,
} from "./independent.model";
import { operatingAccountDtoSchema } from "./operating-account";
import { createMultilineSchema } from "../common/multiline";

/** Validation schema for {@link IndependentDto} */
export const independentDtoSchema = z.object({
	...independentModelSchema.shape,
	balanceLines: z.array(balanceLineDtoSchema),
	operatingAccounts: z.array(z.lazy(() => operatingAccountDtoSchema)),
});
export type IndependentDto = z.infer<typeof independentDtoSchema>;

/** Validation schema for {@link IndependentCreateDto} */
export const independentCreateDtoSchema = z.object({
	...independentModelRawShape,
	balanceLines: createMultilineSchema(
		balanceLineCreateDtoSchema,
		balanceLineUpdateDtoSchema,
	),
});
export type IndependentCreateDto = z.infer<typeof independentCreateDtoSchema>;

/** Validation schema for {@link IndependentUpdateDto} */
export const independentUpdateDtoSchema = independentCreateDtoSchema.partial();
export type IndependentUpdateDto = z.infer<typeof independentUpdateDtoSchema>;

/** Validation schema for {@link IndependentQueryDto} */
export const independentQueryDtoSchema = createQueryObjectSchema(
	independentDtoSchema,
	{ coerce: true, strict: true },
).strict();
export type IndependentQueryDto = z.infer<typeof independentQueryDtoSchema>;

/** Validation schema for {@link IndependentQueryResultsDto} */
export const independentQueryResultsDtoSchema =
	createQueryResultsSchema(independentDtoSchema);
export type IndependentQueryResultsDto = z.infer<
	typeof independentQueryResultsDtoSchema
>;
