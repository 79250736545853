import { Entry } from "~/common";

import { fetchRequest } from "../../../utils/utils";
import { getRequestMethodFromHttpMethod } from "../../utils";

const { routes } =
	Entry.ENTRY_HTTP_CONFIG.children.pillar2.children.vestedBenefits.children
		.closures;

export const VestedBenefitsClosureHttpClient: Entry.VestedBenefitClosureHttp = {
	create: (params, body) =>
		fetchRequest({
			body,
			endpoint: routes.create.path(params),
			method: getRequestMethodFromHttpMethod(routes.create.method),
		}),
	delete: params =>
		fetchRequest({
			endpoint: routes.delete.path(params),
			method: getRequestMethodFromHttpMethod(routes.delete.method),
		}),
	update: (params, body) =>
		fetchRequest({
			body,
			endpoint: routes.update.path(params),
			method: getRequestMethodFromHttpMethod(routes.update.method),
		}),
};
