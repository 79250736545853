import { HttpRoute } from "@nna/core";

import type { HttpConfig as HttpPillarAConfig } from "../http";

export { createHttpDefinitions } from "../../common/premium/http";

/** Full configuration for `Entry/Pillar3/B/premiums` features */
export type HttpConfig = HttpPillarAConfig["children"]["premiums"];
/** HTTP specification for the `Entry/Pillar3/B/premiums` features */
export type Http = HttpRoute.Handlers<HttpConfig["routes"]>;
