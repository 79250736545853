import * as z from "zod";

import { sourceSchema } from "../../source";

/**
 * The kind of reference to use:
 * - custom: "free text"
 * - source: link with {@link sourceSchema}
 * - option: select option within enumeration
 */
export const BALANCE_LINE_REFERENCE_KIND_KEY = "kind";

/** Validation schema for {@link BalanceLineKindCustomModel} */
export const balanceLineKindCustomSchema = z.object({
	[BALANCE_LINE_REFERENCE_KIND_KEY]: z.literal("custom"),
	value: z.string().min(1),
});
/** A {@link BalanceLineKindModel} that uses a custom value ("free text") */
export type BalanceLineKindCustomModel = z.infer<
	typeof balanceLineKindCustomSchema
>;

/** Validation schema for {@link BalanceLineKindCustomModel} */
export const balanceLineKindSourceSchema = z.object({
	[BALANCE_LINE_REFERENCE_KIND_KEY]: z.literal("source"),
	source: sourceSchema,
});
/** A {@link BalanceLineKindModel} that links a {@link sourceSchema} */
export type BalanceLineKindSourceModel = z.infer<
	typeof balanceLineKindSourceSchema
>;

/** To create {@link BalanceLineKindOptionModel} */
function createBalanceLineKindOptionSchema<
	const T extends readonly [string, ...string[]],
>(options: T) {
	return z.object({
		[BALANCE_LINE_REFERENCE_KIND_KEY]: z.literal("option"),
		option: z.enum(options),
	});
}

function createBalanceLineKindSchema<
	const T extends readonly [string, ...string[]],
>(options: T) {
	return z.discriminatedUnion(BALANCE_LINE_REFERENCE_KIND_KEY, [
		balanceLineKindCustomSchema,
		balanceLineKindSourceSchema,
		createBalanceLineKindOptionSchema(options),
	]);
}

// BALANCE LINE TYPE SCHEMAS
/** Whether the balance line is an asset, a liability... */
export const BALANCE_LINE_TYPE_KEY = "type";

export const BALANCE_LINE_KIND_ASSETS_CURRENT_OPTIONS = [
	// Better: extract from the schema instead
	"Créances clients",
	"./. Ducroire",
	"Stock",
	"Actifs transitoires",
] as const;
/** Validation schema for {@link BalanceLineTypeAssetsCurrentModel} */
export const balanceLineTypeAssetsCurrentSchema = z.object({
	[BALANCE_LINE_TYPE_KEY]: z.literal("assets-current"),
	kind: createBalanceLineKindSchema(BALANCE_LINE_KIND_ASSETS_CURRENT_OPTIONS),
});
export type BalanceLineTypeAssetsCurrentModel = z.infer<
	typeof balanceLineTypeAssetsCurrentSchema
>;

export const BALANCE_LINE_KIND_ASSETS_NON_CURRENT_OPTIONS = [
	"Mobilier",
	"Installation informatique",
	"Véhicules",
	"Diverses valeurs matérielles",
	"Goodwill (clientèle)",
] as const;
/** Validation schema for {@link BalanceLineTypeAssetsNonCurrentModel} */
export const balanceLineTypeAssetsNonCurrentSchema = z.object({
	[BALANCE_LINE_TYPE_KEY]: z.literal("assets-non-current"),
	kind: createBalanceLineKindSchema(
		BALANCE_LINE_KIND_ASSETS_NON_CURRENT_OPTIONS,
	),
});
export type BalanceLineTypeAssetsNonCurrentModel = z.infer<
	typeof balanceLineTypeAssetsNonCurrentSchema
>;

export const BALANCE_LINE_KIND_LIABILITIES_CURRENT_OPTIONS = [
	"Dettes fournisseurs",
	"Passifs transitoires",
] as const;
/** Validation schema for {@link BalanceLineTypeLiabilitiesCurrentModel} */
export const balanceLineTypeLiabilitiesCurrentSchema = z.object({
	[BALANCE_LINE_TYPE_KEY]: z.literal("liabilities-current"),
	kind: createBalanceLineKindSchema(
		BALANCE_LINE_KIND_LIABILITIES_CURRENT_OPTIONS,
	),
});
export type BalanceLineTypeLiabilitiesCurrentModel = z.infer<
	typeof balanceLineTypeLiabilitiesCurrentSchema
>;

export const BALANCE_LINE_KIND_OPTIONS_NONE = "-";
export const BALANCE_LINE_KIND_LIABILITIES_NON_CURRENT_OPTIONS = [
	// There is actually no options for this type,
	// but with this all the unions have the same typing and logic
	//	(also this could mean a custom value with no content)
	BALANCE_LINE_KIND_OPTIONS_NONE,
] as const;
/** Validation schema for {@link BalanceLineTypeLiabilitiesNonCurrentModel} */
export const balanceLineTypeLiabilitiesNonCurrentSchema = z.object({
	[BALANCE_LINE_TYPE_KEY]: z.literal("liabilities-non-current"),
	kind: createBalanceLineKindSchema(
		BALANCE_LINE_KIND_LIABILITIES_NON_CURRENT_OPTIONS,
	),
});
export type BalanceLineTypeLiabilitiesNonCurrentModel = z.infer<
	typeof balanceLineTypeLiabilitiesNonCurrentSchema
>;

/** Validation schema for {@link BalanceLineTypeModel} */
export const balanceLineTypeModelSchema = z.discriminatedUnion(
	BALANCE_LINE_TYPE_KEY,
	[
		balanceLineTypeAssetsCurrentSchema,
		balanceLineTypeAssetsNonCurrentSchema,
		balanceLineTypeLiabilitiesCurrentSchema,
		balanceLineTypeLiabilitiesNonCurrentSchema,
	],
);
export type BalanceLineTypeModel = z.infer<typeof balanceLineTypeModelSchema>;

export type BalanceLineTypeType = BalanceLineTypeModel["type"];
export const BALANCE_LINE_TYPE_TYPES: readonly BalanceLineTypeType[] =
	balanceLineTypeModelSchema.options.map(({ shape }) => shape.type.value);

/** The kind property */
export type BalanceLineKindModel = BalanceLineTypeModel["kind"];
/** The value for a kind */
export type BalanceLineKindKind = BalanceLineKindModel["kind"];
export const BALANCE_LINE_KIND_TYPES: readonly BalanceLineKindKind[] =
	balanceLineTypeAssetsNonCurrentSchema.shape.kind.options.map(
		({ shape }) => shape.kind.value,
	);

export type BalanceLineKindOptionModel = Extract<
	BalanceLineKindModel,
	{ kind: "option" }
>;
/** A {@link BalanceLineKindOptionModel} with any of its options */
export interface BalanceLineKindAnyOption
	extends Pick<BalanceLineKindOptionModel, "kind"> {
	// TODO: delete and keep only in backend?
	option: BalanceLineKindOptionOption;
}

/** All known option from {@link BalanceLineKindOptionModel} */
export type BalanceLineKindOptionOption = BalanceLineKindOptionModel["option"];
export const BALANCE_LINE_KIND_OPTION_OPTIONS: readonly BalanceLineKindOptionOption[] =
	[
		// Better: extract from the final schema
		...BALANCE_LINE_KIND_ASSETS_CURRENT_OPTIONS,
		...BALANCE_LINE_KIND_ASSETS_NON_CURRENT_OPTIONS,
		...BALANCE_LINE_KIND_LIABILITIES_CURRENT_OPTIONS,
		...BALANCE_LINE_KIND_LIABILITIES_NON_CURRENT_OPTIONS,
	];

/** From a type, get the expected kind */
export type BalanceLineKindFromBalanceTypeType<T extends BalanceLineTypeType> =
	Extract<BalanceLineTypeModel, { type: T }>["kind"];
/** From a type, get the expected kind {@link BalanceLineKindOptionOption} */
export type BalanceLineKindOptionFromBalanceTypeType<
	T extends BalanceLineTypeType,
> = Extract<
	BalanceLineKindFromBalanceTypeType<T>,
	{ kind: "option" }
>["option"];
