import { HttpRoute } from "@nna/core";

import { type EntryHttpConfig } from "../entry.http";
import { createPillar2AffiliationHttpDefinitions } from "./affiliation";
import { createPillar2PlanHttpDefinitions } from "./plan";
import { createVestedBenefitsHttpDefinitions } from "./vested-benefits";

/**
 * Create routes definitions for `Pillar2` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createPillar2HttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	return {
		children: {
			affiliations: createPillar2AffiliationHttpDefinitions(
				base.addSegment("affiliations"),
			),
			plans: createPillar2PlanHttpDefinitions(base.addSegment("plans")),
			vestedBenefits: createVestedBenefitsHttpDefinitions(
				base.addSegment("vested-benefits"),
			),
		},
	} as const;
}

/** Full configuration for `Entry/Pillar2` features and children */
export type Pillar2HttpConfig = EntryHttpConfig["children"]["pillar2"];
