import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import { pillar2PlanDtoSchema } from "../../../plan/pillar2.plan.dto";
import { pillar2AffiliationDtoSchema } from "../../dtos/pillar2.affiliation.dtos";
import { pillar2AffiliationPlanClosureDtoSchema } from "../closure/dtos/pillar2.affiliation-plan-closure.dto";
import { pillar2AffiliationPlanModelSchema } from "../pillar2.affiliation-plan.model";

/** {@link Pillar2AffiliationPlanDto} with reduced relations */
export const pillar2AffiliationPlanBaseDtoSchema = z.object({
	...pillar2AffiliationPlanModelSchema.shape,
	// Mostly (only) needed to get a "source-name"
	affiliation: z.lazy(() => pillar2AffiliationDtoSchema),
	plan: z.lazy(() => pillar2PlanDtoSchema),
});

/** Validation schema for {@link Pillar2AffiliationPlanDto} */
export const pillar2AffiliationPlanDtoSchema = z.object({
	...pillar2AffiliationPlanBaseDtoSchema.shape,

	closure: z.lazy(() => pillar2AffiliationPlanClosureDtoSchema).nullable(),
});
export type Pillar2AffiliationPlanDto = z.infer<
	typeof pillar2AffiliationPlanDtoSchema
>;

/** Validation schema for {@link Pillar2AffiliationPlanQueryDto} */
export const pillar2AffiliationPlanQueryDtoSchema = createQueryObjectSchema(
	pillar2AffiliationPlanDtoSchema,
	{ coerce: true, strict: true },
).strict();
export type Pillar2AffiliationPlanQueryDto = z.infer<
	typeof pillar2AffiliationPlanQueryDtoSchema
>;

/** Validation schema for {@link Pillar2AffiliationPlanQueryResultsDto} */
export const pillar2AffiliationPlanQueryResultsDtoSchema =
	createQueryResultsSchema(pillar2AffiliationPlanDtoSchema);
export type Pillar2AffiliationPlanQueryResultsDto = z.infer<
	typeof pillar2AffiliationPlanQueryResultsDtoSchema
>;
