import { Model } from "@nna/core";
import * as z from "zod";

import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaWithShapeFieldsV0,
} from "../entry.change-state";
import { EntryCommon } from "../entry.common";
import { MovementData } from "./movement.data";
import { dateCappedSchema } from "../common/date";
import { sourceSchema } from "../source/source.model";

/** Zod Shape for {@link movementModelSchema} */
export const movementModelRawShape = {
	amount: EntryCommon.amountSchema,
	data: MovementData.schema,
	date: dateCappedSchema({}),

	from: sourceSchema.describe("Origin of the amount"),
	to: sourceSchema.describe("Destination of the amount"),
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link MovementModel} */
export const movementModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaWithShapeFieldsV0(movementModelRawShape, [
			// `from` & `to` can not be modified once created
			"amount",
			"data",
			"date",
		]),
		movementModelRawShape,
	),
});
/** An movement of for a  "MovementSource" */
export type MovementModel = z.infer<typeof movementModelSchema>;
