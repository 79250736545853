import { Model } from "@nna/core";
import * as z from "zod";

import { dateCappedSchema } from "../../common/date";
import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../entry.change-state";
import { EntryCommon } from "../../entry.common";
import { stockModelSchema } from "../stock.model";

/** Zod Shape for {@link stockDividendModelSchema} */
export const stockDividendModelRawShape = {
	date: dateCappedSchema({}),
	amount: EntryCommon.positiveAmountSchema,
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link StockModel} */
export const stockDividendModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(stockDividendModelRawShape),
		{
			...stockDividendModelRawShape,
			fkStock: stockModelSchema.shape._id,
		},
	),
});

export type StockDividendModel = z.infer<typeof stockDividendModelSchema>;
