import { ModelNumber } from "@nna/core";
import * as z from "zod";

import { stockTransactionDtoBaseSchema } from "../../../stock/transaction/stock-transaction-base.dto";
import { createSchema } from "../lib";

/** Unique type discrimination for {@link Model} */
export const TYPE = "stock-transaction";

/** Validation schema for {@link Model} */
export const schema = createSchema(TYPE, {
	fkStockTransaction: ModelNumber.schema.shape._id, // Avoid cycles
});

export type Model = z.infer<typeof schema>;

/** Validation schema for {@link Dto} */
export const dtoSchema = z.object({
	...schema.shape,
	stockTransaction: z.lazy(() => stockTransactionDtoBaseSchema),
});
/**
 * Dto extension of {@link Model}.
 * Principally for query/filter purposes.
 */
export type Dto = z.infer<typeof dtoSchema>;
