import * as z from "zod";

import { dtoBaseSchema as pillar3ADtoSchema } from "../../pillar3/a/dtos/dtos";
import { modelSchema as pillar3AModelSchema } from "../../pillar3/a/model";
import { createSchema } from "../lib";

/** Unique type discrimination for {@link Model} */
export const TYPE = "pillar3-a";

/** Validation schema for {@link Model} */
export const schema = createSchema(TYPE, {
	fkPillar3A: pillar3AModelSchema.shape._id,
});
/** Movement source for an {@link pillar3AModelSchema} */
export type Model = z.infer<typeof schema>;

/** Validation schema for {@link Dto} */
export const dtoSchema = z.object({
	...schema.shape,
	pillar3A: z.lazy(() => pillar3ADtoSchema),
});
/**
 * Dto extension of {@link Model}.
 * Principally for query/filter purposes.
 */
export type Dto = z.infer<typeof dtoSchema>;
