import * as z from "zod";

import { SourceOptions } from "./source.options";

const sources = [
	SourceOptions.Account,
	SourceOptions.Pillar2AffiliationPlan,
	SourceOptions.Pillar2VestedBenefits,
	SourceOptions.Pillar3A,
	SourceOptions.Stock,
	SourceOptions.Transferable,
	SourceOptions.Loan,
	SourceOptions.Credit,
	// TODO: more sources
] as const satisfies Array<{
	dtoSchema: z.ZodType<SourceOptions.BaseModel<string>>;
	schema: z.ZodType<SourceOptions.BaseModel<string>>;
}>;
const mapInSchemas = <T>(fn: (source: (typeof sources)[number]) => T) =>
	// Small hack to indicate to zod that the array is not empty
	sources.map(fn) as [T, ...T[]];

/** Validation schema for {@link SourceModel} */
export const sourceSchema = z.discriminatedUnion(
	SourceOptions.DISCRIMINATION_KEY,
	mapInSchemas(({ schema }) => schema),
);
/** Model of known sources */
export type SourceModel = z.infer<typeof sourceSchema>;

/** Known type for {@link SourceModel} */
export type SourceType = SourceModel[typeof SourceOptions.DISCRIMINATION_KEY];
/** List of available types for {@link SourceModel} */
export const SOURCES_TYPES: readonly SourceType[] = sourceSchema.options.map(
	({ shape }) => shape[SourceOptions.DISCRIMINATION_KEY].value,
);

/** Validation schema for {@link SourceDto} */
export const sourceDtoSchema = z.discriminatedUnion(
	SourceOptions.DISCRIMINATION_KEY,
	mapInSchemas(({ dtoSchema }) => dtoSchema),
);
/**
 * Dto extension of {@link SourceModel}.
 * Principally for query/filter purposes.
 */
export type SourceDto = z.infer<typeof sourceDtoSchema>;

/**
 * Extracts from the {@link SourceModel} the sub-type of the given template
 *
 * @example
 * type SourceAccount = ModelOfType<"account">;
 * // Is equal to
 * type SourceAccount = SourceOptions.Account.Model;
 */
export type SourceModelOfType<T extends SourceType> = Extract<
	SourceModel,
	SourceOptions.BaseModel<T>
>;
/** Similar to {@link SourceModelOfType} but extracts from the {@link SourceDto} */
export type SourceDtoOfType<T extends SourceType> = Extract<
	SourceDto,
	SourceOptions.BaseModel<T>
>;
