import { Entry } from "../../..";

type BalanceLineTypeLimited = Partial<
	Pick<Entry.BalanceLineDto, "type" | "valueAccounting" | "valueReal">
>;

/**
 * Helper function that calculate the total assets from a list of balance lines
 */
export function getTotalAssets(balanceLines: BalanceLineTypeLimited[]) {
	const assets = balanceLines.filter(
		({ type }) =>
			type &&
			(type.type === "assets-current" ||
				type.type === "assets-non-current"),
	);

	return {
		totalAccounting: assets.reduce(
			(acc, balanceLine) => acc + (balanceLine.valueAccounting ?? 0),
			0,
		),
		totalReal: assets.reduce(
			(acc, balanceLine) => acc + (balanceLine.valueReal ?? 0),
			0,
		),
	};
}

/**
 * Helper function that calculate the total liabilities (without equities) from a list of balance lines
 */
export function getTotalLiabilities(balanceLines: BalanceLineTypeLimited[]) {
	const assets = balanceLines.filter(
		({ type }) =>
			type &&
			(type.type === "liabilities-current" ||
				type.type === "liabilities-non-current"),
	);

	return {
		totalAccounting: assets.reduce(
			(acc, balanceLine) => acc + (balanceLine.valueAccounting ?? 0),
			0,
		),
		totalReal: assets.reduce(
			(acc, balanceLine) => acc + (balanceLine.valueReal ?? 0),
			0,
		),
	};
}
