import * as z from "zod";

import { pillar2AffiliationPlanClosureDtoSchema } from "../../../pillar2/affiliation/plan/closure/dtos";
import { pillar2AffiliationPlanClosureModelSchema } from "../../../pillar2/affiliation/plan/closure/pillar2.affiliation-plan-closure.model";
import { createSchema } from "../lib";

/** Unique type discrimination for {@link Model} */
export const TYPE = "pillar2-affiliation-plan-closure";

/** Validation schema for {@link Model} */
export const schema = createSchema(TYPE, {
	fkPillar2AffiliationPlanClosure:
		pillar2AffiliationPlanClosureModelSchema.shape._id,
});
/** Movement Group representation of a {@link pillar2AffiliationPlanClosureModelSchema} */
export type Model = z.infer<typeof schema>;

/** Validation schema for {@link Dto} */
export const dtoSchema = z.object({
	...schema.shape,
	pillar2AffiliationPlanClosure: z.lazy(
		() => pillar2AffiliationPlanClosureDtoSchema,
	),
});
/**
 * Dto extension of {@link Model}.
 * Principally for query/filter purposes.
 */
export type Dto = z.infer<typeof dtoSchema>;
