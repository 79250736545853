import { Model } from "@nna/core";
import * as z from "zod";

import { dateCappedSchema } from "../../common";
import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../entry.change-state";
import { EntryCommon } from "../../entry.common";
import { entryModelSchema } from "../../entry.model";

export const vestedBenefitsModelRawShape = {
	entitled: EntryCommon.customerSingleSchema,
	name: z.string().min(2).describe("Name of the account/establishment"),
	identifier: z.string().describe("Generally the (bank) account number"),
	dateStart: dateCappedSchema({}).nullable(),

	// Behavior of the following slightly change depending on the date.
	amountInitial: EntryCommon.amountSchema
		.nullable()
		.or(z.literal("").transform(() => null)) // FORMIK_456: To handle Formik empty string state
		.describe("Initial amount, unused when 'future'"),

	interest: EntryCommon.percentageSchema
		.nullable()
		.or(z.literal("").transform(() => null)), // FORMIK_456: To handle Formik empty string state
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link VestedBenefitsModel} */
export const vestedBenefitsModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(vestedBenefitsModelRawShape),
		{
			...vestedBenefitsModelRawShape,
			fkEntry: entryModelSchema.shape._id,
		},
	),
});
/** A vested benefits account ("compte de libre passage") of an `Entry` */
export type VestedBenefitsModel = z.infer<typeof vestedBenefitsModelSchema>;
