import * as z from "zod";

import { createModelSchema as createClosureModelSchema } from "../../common/closure/model";

/** Validation schema fro {@link ClosureReason} */
export const closureReasonSchema = z.enum([
	// 'Versement capital'
	"capital-payment",
	// 'Changement de contrat'
	"contract-change",
]);
export type ClosureReason = z.infer<typeof closureReasonSchema>;

/** Raw shape for {@link Model} */
export const closureRawShape = {
	reason: closureReasonSchema,
} as const satisfies z.ZodRawShape;

/** Validation schemas for {@link ModelClosure}, {@link ModelRetirement} & {@link Model} */
export const { modelClosureSchema, modelRetirementSchema, modelSchema } =
	createClosureModelSchema(closureRawShape);
export type ModelClosure = z.infer<typeof modelClosureSchema>;
export type ModelRetirement = z.infer<typeof modelRetirementSchema>;
export type Model = z.infer<typeof modelSchema>;
