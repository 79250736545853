import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import { characteristicsDtoSchema } from "./characteristics";
import {
	pillar2PlanModelRawShape,
	pillar2PlanModelSchema,
} from "./pillar2.plan.model";

/** Validation schema for {@link Pillar2PlanDto} */
export const pillar2PlanDtoSchema = z.object({
	...pillar2PlanModelSchema.shape,
	characteristics: z.array(z.lazy(() => characteristicsDtoSchema)),
});
export type Pillar2PlanDto = z.infer<typeof pillar2PlanDtoSchema>;

/** Validation schema for {@link Pillar2PlanCreateDto} */
export const pillar2PlanCreateDtoSchema = z.object(pillar2PlanModelRawShape);
export type Pillar2PlanCreateDto = z.infer<typeof pillar2PlanCreateDtoSchema>;

/** Validation schema for {@link Pillar2PlanUpdateDto} */
export const pillar2PlanUpdateDtoSchema = pillar2PlanCreateDtoSchema.partial();
export type Pillar2PlanUpdateDto = z.infer<typeof pillar2PlanUpdateDtoSchema>;

/** Validation schema for {@link Pillar2PlanQueryDto} */
export const pillar2PlanQueryDtoSchema = createQueryObjectSchema(
	pillar2PlanDtoSchema,
	{ coerce: true, strict: true },
).strict();
export type Pillar2PlanQueryDto = z.infer<typeof pillar2PlanQueryDtoSchema>;

/** Validation schema for {@link Pillar2PlanQueryResultsDto} */
export const pillar2PlanQueryResultsDtoSchema =
	createQueryResultsSchema(pillar2PlanDtoSchema);
export type Pillar2PlanQueryResultsDto = z.infer<
	typeof pillar2PlanQueryResultsDtoSchema
>;
