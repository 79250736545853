import { Model } from "@nna/core";
import { z } from "zod";

import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../entry.change-state";
import { EntryCommon } from "../../entry.common";
import { entryModelSchema } from "../../entry.model";
import { methodCalculationSchema } from "../common";

/** Zod shape for {@link modelSchema}*/
export const rawShape = {
	insuredPeople: EntryCommon.customerSchema.describe(
		"The customer(s) that are insured",
	),
	establishment: z.string().min(2).describe(""),
	policeNumber: z
		.string()
		.describe("The police number attributed by the insurance"),
	startDate: z.date().describe("Start date of the insurance"),
	endDate: z.date().describe("End date of the insurance"),
	surrenderValue: EntryCommon.amountSchema
		.nullable()
		.describe("Surrender value as of 12/31/2023 (year-1)"),
	guaranteedFinalCapital: EntryCommon.amountSchema
		.nullable()
		.describe("Guaranteed final capital"),
	calculationMethod: methodCalculationSchema.describe(
		"Calculation method with `assurance`.<br>Unused with `bank` type",
	),
	currentBalance: EntryCommon.amountSchema
		.nullable()
		.describe("Current balance as of 31.12.2023 (year-1)"), // In figma, it correspond to "Valeur de rachat impossable"
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link Model}*/
export const modelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(rawShape),
		{
			...rawShape,
			fkEntry: entryModelSchema.shape._id,
		},
	),
});

export type Model = z.infer<typeof modelSchema>;
