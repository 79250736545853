import * as z from "zod";

import { createModelSchema as createClosureModelSchema } from "../../common/closure/model";

/** Validation schemas for {@link ModelClosure}, {@link ModelRetirement} & {@link Model} */
export const { modelClosureSchema, modelRetirementSchema, modelSchema } =
	createClosureModelSchema({});
export type ModelClosure = z.infer<typeof modelClosureSchema>;
export type ModelRetirement = z.infer<typeof modelRetirementSchema>;
export type Model = z.infer<typeof modelSchema>;
