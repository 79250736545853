import { QueryFilter } from "@nna/core";

import { SourceDto } from "../dtos";
import { SourceModel } from "../source.model";

/**
 * Gets an "unique key" from a {@link SourceModel}.
 *
 * Used for {@link compare}
 *
 * @param source to get an unique key from
 * @returns a (unique) key
 */
export function getUniqueKey(source: SourceModel): string {
	switch (source.type) {
		case "account":
			return `${source.type}/${source.fkAccount}`;
		case "pillar2-affiliation-plan":
			return `${source.type}/${source.fkPillar2AffiliationPlan}`;
		case "pillar2-vested-benefits":
			return `${source.type}/${source.fkPillar2VestedBenefits}`;
		case "pillar3-a":
			return `${source.type}/${source.fkPillar3A}`;
		case "transferable":
			return `${source.type}/${source.fkTransferable}`;
		case "stock":
			return `${source.type}/${source.fkStock}`;
		case "loan":
			return `${source.type}/${source.fkLoan}`;
		case "credit":
			return `${source.type}/${source.fkCredit}`;
	}
}

/**
 * Gets the query-able format from a {@link SourceModel}
 *
 * @param source to transform into a query-able data
 * @returns a usable chunk of {@link QueryFilter} for the given source
 */
export function getFilterQuery(source: SourceModel): QueryFilter<SourceDto> {
	switch (source.type) {
		case "account":
			return { account: { _id: source.fkAccount }, type: source.type };
		case "pillar2-affiliation-plan":
			return {
				pillar2AffiliationPlan: {
					_id: source.fkPillar2AffiliationPlan,
				},
				type: source.type,
			};
		case "pillar2-vested-benefits":
			return {
				pillar2VestedBenefits: { _id: source.fkPillar2VestedBenefits },
				type: source.type,
			};
		case "pillar3-a":
			return {
				pillar3A: { _id: source.fkPillar3A },
				type: source.type,
			};
		case "transferable":
			return {
				transferable: { _id: source.fkTransferable },
				type: source.type,
			};
		case "stock":
			return {
				stock: { _id: source.fkStock },
				type: source.type,
			};
		case "loan":
			return {
				loan: { _id: source.fkLoan },
				type: source.type,
			};
		case "credit":
			return {
				credit: { _id: source.fkCredit },
				type: source.type,
			};
	}
}

/**
 * Compare 2 {@link SourceModel}
 *
 * @param a first element to compare
 * @param b second element to compare
 * @returns if the 2 source are identical
 */
export function compare(a: SourceModel, b: SourceModel): boolean {
	return getUniqueKey(a) === getUniqueKey(b);
}
