import { HttpRoute } from "@nna/core";

import { createCreditHttpDefinitions } from "./credit";
import { EntryFindOneDto } from "./dtos";
import { PLAN_HTTP_ROOT_BY_ID } from "../plan";
import { createAccountHttpDefinitions } from "./account";
import { createCurrentExpensesHttpDefinitions } from "./expense/current";
import { createExceptionalExpensesHttpDefinitions } from "./expense/exceptional";
import { createIndependentHttpDefinitions } from "./independent";
import { createLoanHttpDefinitions } from "./loan";
import { createMovementHttpDefinitions } from "./movement";
import { createPersonalHttpDefinitions } from "./personal";
import { createChildHttpDefinitions } from "./personal/child";
import { createResidenceHttpDefinitions } from "./personal/residence";
import { createPillar1HttpDef } from "./pillar1/pillar1.http";
import { createPillar2HttpDefinitions } from "./pillar2/pillar2.http";
import { Pillar3 } from "./pillar3";
import { createRecipeHttpDefinitions } from "./recipe";
import { createSourceHttpDefinitions } from "./source";
import { createStockHttpDefinitions } from "./stock";
import { createTransferableHttpDefinitions } from "./transferable";

// TODO
/** Entrypoint route */
const root = PLAN_HTTP_ROOT_BY_ID.addSegment("entries");
const rootById = root.addSegment({
	param: "entryId",
	type: "param",
	validation: "number",
});

/**
 * HTTP configuration for `Entry` features
 * It is a "indirect-child" of `Plan`
 */
export const ENTRY_HTTP_CONFIG = {
	children: {
		// Not directly shown (in the navBar at least)
		/** General entrypoint for `movement` */
		movements: createMovementHttpDefinitions(
			rootById.addSegment("movements"),
		),
		sources: createSourceHttpDefinitions(rootById.addSegment("sources")),

		// 'information' section
		children: createChildHttpDefinitions(rootById.addSegment("children")),
		personals: createPersonalHttpDefinitions(
			rootById.addSegment("personals"),
		),
		residences: createResidenceHttpDefinitions(
			rootById.addSegment("residences"),
		),

		// 'budget' section
		current: createCurrentExpensesHttpDefinitions(
			rootById.addSegment("current"),
		),
		exceptional: createExceptionalExpensesHttpDefinitions(
			rootById.addSegment("exceptional"),
		),
		recipes: createRecipeHttpDefinitions(rootById.addSegment("recipes")),

		// 'credits/loan' section
		credits: createCreditHttpDefinitions(rootById.addSegment("credits")),
		loans: createLoanHttpDefinitions(rootById.addSegment("loans")),

		stocks: createStockHttpDefinitions(rootById.addSegment("stocks")),

		// 'furniture' ("mobilier") section
		accounts: createAccountHttpDefinitions(rootById.addSegment("accounts")),
		transferables: createTransferableHttpDefinitions(
			rootById.addSegment("transferables"),
		),

		// Pillars section
		pillar1: createPillar1HttpDef(rootById.addSegment("pillar1")),
		pillar2: createPillar2HttpDefinitions(rootById.addSegment("pillar2")),
		pillar3: {
			a: Pillar3.A.createHttpDefinitions(
				rootById.addSegment("pillar3/a"),
			),
			b: Pillar3.B.createHttpDefinitions(
				rootById.addSegment("pillar3/b"),
			),
		},

		// Independent section
		independents: createIndependentHttpDefinitions(
			rootById.addSegment("independents"),
		),
	},
	routes: {
		// `findAndCount` not needed for v1
		findById: rootById.get<() => Promise<EntryFindOneDto>>(),
	} satisfies HttpRoute.Definitions,
} as const;

/** Full configuration for `Entry` features and children */
export type EntryHttpConfig = typeof ENTRY_HTTP_CONFIG;

/** HTTP specification for the `Entry` feature */
export type EntryHttp = HttpRoute.Handlers<EntryHttpConfig["routes"]>;
