import * as z from "zod";

import { sourceAmountActionsSchema } from "../../../../source-amount/source-amount.actions";
import { Closure } from "../../../common";
import { closureRawShape } from "../model";

/** Validation schema for {@link CreateDto} */
export const createDtoSchema = Closure.createValidationSchema(closureRawShape, {
	funds: z.lazy(() => sourceAmountActionsSchema),
});
export type CreateDto = z.infer<typeof createDtoSchema>;

export type { CreateDto as UpdateDto };
export { createDtoSchema as updateDtoSchema };
