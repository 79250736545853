import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import {
	operatingAccountModelRawShape,
	operatingAccountModelSchema,
} from "./operating-account.model";
import {
	statementLineCreateDtoSchema,
	statementLineDtoSchema,
	statementLineUpdateDtoSchema,
} from "./statement-line";
import { createMultilineSchema } from "../../common/multiline";

/** Validation schema for {@link OperatingAccountDto} */
export const operatingAccountDtoSchema = z.object({
	...operatingAccountModelSchema.shape,
	statementLines: z.array(statementLineDtoSchema),
});
export type OperatingAccountDto = z.infer<typeof operatingAccountDtoSchema>;

/** Validation schema for {@link OperatingAccountCreateDto} */
export const operatingAccountCreateDtoSchema = z.object({
	...operatingAccountModelRawShape,
	statementLines: createMultilineSchema(
		statementLineCreateDtoSchema,
		statementLineUpdateDtoSchema,
	),
});
export type OperatingAccountCreateDto = z.infer<
	typeof operatingAccountCreateDtoSchema
>;

/** Validation schema for {@link OperatingAccountUpdateDto} */
export const operatingAccountUpdateDtoSchema =
	operatingAccountCreateDtoSchema.partial();
export type OperatingAccountUpdateDto = z.infer<
	typeof operatingAccountUpdateDtoSchema
>;

/** Validation schema for {@link OperatingAccountQueryDto} */
export const operatingAccountQueryDtoSchema = createQueryObjectSchema(
	operatingAccountDtoSchema,
	{ coerce: true, strict: true },
).strict();
export type OperatingAccountQueryDto = z.infer<
	typeof operatingAccountQueryDtoSchema
>;

/** Validation schema for {@link OperatingAccountQueryResultsDto} */
export const operatingAccountQueryResultsDtoSchema = createQueryResultsSchema(
	operatingAccountDtoSchema,
);
export type OperatingAccountQueryResultsDto = z.infer<
	typeof operatingAccountQueryResultsDtoSchema
>;
