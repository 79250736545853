import * as z from "zod";

import { pillar2AffiliationPlanBaseDtoSchema } from "../../pillar2/affiliation/plan/dtos/pillar2.affiliation-plan.dtos";
import { pillar2AffiliationPlanModelSchema } from "../../pillar2/affiliation/plan/pillar2.affiliation-plan.model";
import { createSchema } from "../lib";

/** Unique type discrimination for {@link Model} */
export const TYPE = "pillar2-affiliation-plan";

/** Validation schema for {@link Model} */
export const schema = createSchema(TYPE, {
	fkPillar2AffiliationPlan: pillar2AffiliationPlanModelSchema.shape._id,
});
/** Movement source for an {@link pillar2AffiliationPlanModelSchema} */
export type Model = z.infer<typeof schema>;

/** Validation schema for {@link Dto} */
export const dtoSchema = z.object({
	...schema.shape,
	pillar2AffiliationPlan: z.lazy(() => pillar2AffiliationPlanBaseDtoSchema),
});
/**
 * Dto extension of {@link Model}.
 * Principally for query/filter purposes.
 */
export type Dto = z.infer<typeof dtoSchema>;
