import { HttpRoute } from "@nna/core";

import { HttpConfig as Pillar3AHttpConfig } from "../http";
import type * as DTOs from "./dtos";

/**
 * Create routes definitions for `Pillar3/A/Closure` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createHttpDefinitions<const BASE extends HttpRoute.BuilderAny>(
	base: BASE,
) {
	const baseById = base.addSegment({
		param: "closureId",
		type: "param",
		validation: "number",
	});

	return {
		children: {},
		routes: {
			create: base.post<(body: DTOs.CreateDto) => Promise<DTOs.Dto>>(),
			delete: baseById.delete<() => Promise<DTOs.Dto>>(),
			update: baseById.patch<
				(body: DTOs.UpdateDto) => Promise<DTOs.Dto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

/** Full configuration for `Entry/Pillar3/A/Closure` features and children */
export type HttpConfig = Pillar3AHttpConfig["children"]["closures"];
/** HTTP specification for the `Entry/Pillar3/A/Closure` features */
export type Http = HttpRoute.Handlers<HttpConfig["routes"]>;
