import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import {
	balanceLineModelRawShape,
	balanceLineModelSchema,
} from "./balance-line.model";

/** Validation schema for {@link BalanceLineDto} */
export const balanceLineDtoSchema = z.object({
	...balanceLineModelSchema.shape,
});
export type BalanceLineDto = z.infer<typeof balanceLineDtoSchema>;

/** Validation schema for {@link BalanceLineCreateDto} */
export const balanceLineCreateDtoSchema = z.object(balanceLineModelRawShape);
export type BalanceLineCreateDto = z.infer<typeof balanceLineCreateDtoSchema>;

/** Validation schema for {@link BalanceLineUpdateDto} */
export const balanceLineUpdateDtoSchema = balanceLineCreateDtoSchema.partial();
export type BalanceLineUpdateDto = z.infer<typeof balanceLineUpdateDtoSchema>;

/** Validation schema for {@link BalanceLineQueryDto} */
export const balanceLineQueryDtoSchema = createQueryObjectSchema(
	balanceLineDtoSchema,
	{ coerce: true, strict: true },
).strict();
export type BalanceLineQueryDto = z.infer<typeof balanceLineQueryDtoSchema>;

/** Validation schema for {@link BalanceLineQueryResultsDto} */
export const balanceLineQueryResultsDtoSchema =
	createQueryResultsSchema(balanceLineDtoSchema);
export type BalanceLineQueryResultsDto = z.infer<
	typeof balanceLineQueryResultsDtoSchema
>;
