import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import {
	characteristicsModelRawShape,
	characteristicsModelSchema,
} from "./characteristics.model";
import {
	characteristicsContributionCreateDtoSchema,
	characteristicsContributionDtoSchema,
	characteristicsContributionUpdateDtoSchema,
} from "./contribution";
import {
	characteristicsRateCreateDtoSchema,
	characteristicsRateDtoSchema,
	characteristicsRateUpdateDtoSchema,
} from "./rate";
import { createMultilineSchema } from "../../../common/multiline";

/** Validation schema for {@link CharacteristicsDto} */
export const characteristicsDtoSchema = z.object({
	...characteristicsModelSchema.shape,
	contributions: z.array(characteristicsContributionDtoSchema),
	rates: z.array(characteristicsRateDtoSchema),
});
export type CharacteristicsDto = z.infer<typeof characteristicsDtoSchema>;

/** Validation schema for {@link CharacteristicsCreateDto} */
export const characteristicsCreateDtoSchema = z.object({
	...characteristicsModelRawShape,
	contributions: createMultilineSchema(
		characteristicsContributionCreateDtoSchema,
		characteristicsContributionUpdateDtoSchema,
	),
	rates: createMultilineSchema(
		characteristicsRateCreateDtoSchema,
		characteristicsRateUpdateDtoSchema,
	),
});
export type CharacteristicsCreateDto = z.infer<
	typeof characteristicsCreateDtoSchema
>;

/** Validation schema for {@link CharacteristicsUpdateDto} */
export const characteristicsUpdateDtoSchema =
	characteristicsCreateDtoSchema.partial();
export type CharacteristicsUpdateDto = z.infer<
	typeof characteristicsUpdateDtoSchema
>;

/** Validation schema for {@link CharacteristicsQueryDto} */
export const characteristicsQueryDtoSchema = createQueryObjectSchema(
	characteristicsDtoSchema,
	{ coerce: true, strict: true },
).strict();
export type CharacteristicsQueryDto = z.infer<
	typeof characteristicsQueryDtoSchema
>;

/** Validation schema for {@link CharacteristicsQueryResultsDto} */
export const characteristicsQueryResultsDtoSchema = createQueryResultsSchema(
	characteristicsDtoSchema,
);
export type CharacteristicsQueryResultsDto = z.infer<
	typeof characteristicsQueryResultsDtoSchema
>;
