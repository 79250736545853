import * as z from "zod";

import { createSchema } from "../lib";

/** Unique type discrimination for {@link Model} */
export const TYPE = "pillar2/vested-benefits";

/** Validation schema for {@link Kind} */
export const kindSchema = z.enum(["EPL", "divorce-allowance"]);
/** The possible kind ("sub-type") of movement for {@link Model} */
export type Kind = z.infer<typeof kindSchema>;

/** Validation schema for {@link Model} */
export const schema = createSchema(TYPE, {
	subType: kindSchema.describe('"Sub-type" of the movement'),
});
/** Extra data for a movement with a 'pillar 2 vested-benefits' source */
export type Model = z.infer<typeof schema>;
