import { Model } from "@nna/core";
import * as z from "zod";

import { dateCappedSchema } from "../../common/date";
import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../entry.change-state";
import { stockModelSchema } from "../stock.model";

/** Validation schema for {@link StockTransactionType} */
export const stockTransactionTypeSchema = z.enum([
	"CAPITAL_INCREASE",
	"BUY",
	"SELL",
]);
export type StockTransactionType = z.infer<typeof stockTransactionTypeSchema>;

/** Zod Shape for {@link stockTransactionModelSchema} */
export const stockTransactionModelRawShape = {
	type: stockTransactionTypeSchema,
	date: dateCappedSchema({}),
	amountOfShares: z.number().int().gt(0).describe("amount of shares"),
	valuePerShare: z.number().gte(0),
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link StockModel} */
export const stockTransactionModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(stockTransactionModelRawShape),
		{
			...stockTransactionModelRawShape,
			fkStock: stockModelSchema.shape._id,
		},
	),
});
export type StockTransactionModel = z.infer<typeof stockTransactionModelSchema>;
