import * as z from "zod";

import { sourceAmountActionsSchema } from "../../../../source-amount/source-amount.actions";
import {
	vestedBenefitClosureModelSchema,
	vestedBenefitClosureTypeSchema,
} from "../vested-benefit-closure.model";

/** Validation schema for {@link VestedBenefitClosureCreateDto} */
export const vestedBenefitClosureCreateDtoSchema = z.object({
	date: vestedBenefitClosureModelSchema.shape.date,
	funds: z.lazy(() => sourceAmountActionsSchema),
	closureType: vestedBenefitClosureTypeSchema,
});
export type VestedBenefitClosureCreateDto = z.infer<
	typeof vestedBenefitClosureCreateDtoSchema
>;

/** Validation schema for {@link VestedBenefitClosureUpdateDto} */
export const vestedBenefitClosureUpdateDtoSchema =
	vestedBenefitClosureCreateDtoSchema.partial();
export type VestedBenefitClosureUpdateDto = z.infer<
	typeof vestedBenefitClosureUpdateDtoSchema
>;
