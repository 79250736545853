import * as dateFns from "date-fns";

/**
 * Generic closer-to-date getter.
 * Gets the 'item' (entity) that is the closest to the given date reference.
 * The item date is referenced by 'itemDateKey'.
 *
 * 1. If {@link dateRef} is smaller than any item => more recent item
 * 2. If {@link dateRef} is older than any item => oldest item
 * 3. Otherwise, the item with its "time-range" in the given {@link dateRef}
 *
 * @param items to extract the closest item from
 * @param itemDateKey the key to extract the date from the item
 * @param dateRef the date to compare with @default now
 * @returns the item closest to the given date, null when no items given
 */
export function getCloserToDate<T extends Record<K, Date>, K extends keyof T>(
	items: T[],
	itemDateKey: K,
	dateRef = new Date(),
): T | null {
	if (items.length === 0) {
		return null;
	}

	const fn = (items: readonly T[], current: T): T => {
		if (items.length === 0) {
			// Nothing else => return the remaining
			return current;
		}

		const [first, ...rest] = items;
		const test = first[itemDateKey];
		if (dateFns.isBefore(dateRef, test)) {
			// The date of the first element is greater than the referential date
			//	=> return the current one
			return current;
		}

		return fn(rest, first);
	};

	// To ensure the order
	const [first, ...rest] = items
		.slice()
		.sort(({ [itemDateKey]: a }, { [itemDateKey]: b }) =>
			dateFns.compareAsc(a, b),
		);
	return fn(rest, first);
}
