import { createQueryObjectSchema } from "@nna/core";
import * as z from "zod";

import { vestedBenefitDtoSchema } from "./vested-benefits.dto";

/** Validation schema for {@link VestedBenefitsQueryDto} */
export const vestedBenefitsQueryDtoSchema = createQueryObjectSchema(
	vestedBenefitDtoSchema,
	{
		coerce: true,
		strict: true,
	},
).strict();
/** DTO used to filter `vestedBenefit` */
export type VestedBenefitsQueryDto = z.infer<
	typeof vestedBenefitsQueryDtoSchema
>;
