import * as z from "zod";

import { sourceSchema } from "../../../../source/source.model";
import { rawShape } from "../model";

/** Validation schema for {@link CreateDto} */
export const createDtoSchema = z.object({ ...rawShape, source: sourceSchema });
export type CreateDto = z.infer<typeof createDtoSchema>;

/** Validation schema for {@link UpdateDto} */
export const updateDtoSchema = createDtoSchema
	.omit({
		// As for movement (and others), the source can not be changed afterwards
		source: true,
	})
	.partial();
export type UpdateDto = z.infer<typeof updateDtoSchema>;
