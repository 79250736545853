import { Model } from "@nna/core";
import * as z from "zod";

import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../../entry.change-state";
import { EntryCommon } from "../../../entry.common";
import { pillar2PlanModelSchema } from "../../plan/pillar2.plan.model";
import { pillar2AffiliationModelSchema } from "../pillar2.affiliation.model";

/** Zod Shape for {@link pillar2AffiliationPlanModelSchema} */
export const pillar2AffiliationPlanModelRawShape = {
	fkPlan: pillar2PlanModelSchema.shape._id.describe(
		"FK to the applied pillar2 plan",
	),

	entryDate: EntryCommon.dateCappedSchema({}).describe(
		"Date of entry in the plan",
	),
	pensionAssetsAmount: EntryCommon.positiveAmountSchema,
	pensionAssetsDate: EntryCommon.dateCappedSchema({}),
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link RecipeModel} */
export const pillar2AffiliationPlanModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(pillar2AffiliationPlanModelRawShape),
		{
			...pillar2AffiliationPlanModelRawShape,
			fkAffiliation: pillar2AffiliationModelSchema.shape._id,
		},
	),
});
export type Pillar2AffiliationPlanModel = z.infer<
	typeof pillar2AffiliationPlanModelSchema
>;
