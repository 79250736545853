import { HttpRoute } from "@nna/core";

import type * as DTOs from "./dtos";

/**
 * Create routes definitions for `Pillar3/<X>/Premiums` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createHttpDefinitions<const BASE extends HttpRoute.BuilderAny>(
	base: BASE,
) {
	const baseById = base.addSegment({
		param: "premiumId",
		type: "param",
		validation: "number",
	});

	return {
		children: {},
		routes: {
			findAndCount:
				base.get<
					(query: DTOs.QueryDto) => Promise<DTOs.QueryResultsDto>
				>(),
			findById: baseById.get<() => Promise<DTOs.Dto>>(),

			create: base.post<(body: DTOs.CreateDto) => Promise<DTOs.Dto>>(),
			delete: baseById.delete<() => Promise<DTOs.Dto>>(),
			update: baseById.patch<
				(body: DTOs.UpdateDto) => Promise<DTOs.Dto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}
