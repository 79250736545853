import * as dateFns from "date-fns";

import { Entry } from "../../../..";
import { PlanDto } from "../../../../plan/dtos";
import * as Utils from "../../../utils";
import { Pillar2PlanDto } from "../pillar2.plan.dto";

/**
 * Indicate if the pillar2 plan is future or not, based on the most recent characteristics
 * Specific to pillar2 plan: we consider a pillar2 plan as future if the oldest characteristics is after the END of the plan year
 *
 * @param plan the plan (to get the year)
 * @param pillar2Plan the pillar2 plan (to get the characteristics)
 * @returns true if the pillar2Plan is future, false otherwise
 */
export function isFuture(
	plan: PlanDto,
	pillar2Plan: Partial<Pick<Pillar2PlanDto, "characteristics">>,
) {
	const characteristics = Entry.Utils.getCloserToDate(
		pillar2Plan.characteristics ?? [],
		"dateStart",
	);
	if (characteristics === null) {
		return false;
	}

	return dateFns.isAfter(
		characteristics.dateStart,
		Utils.date.fromYear(plan.year, false),
	);
}
