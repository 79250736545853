import * as z from "zod";

import { dtoSchema as pillar3AClosureDtoSchema } from "../../../pillar3/a/closure/dtos/dtos";
import { modelClosureSchema as pillar3AClosureModelSchema } from "../../../pillar3/a/closure/model";
import { createSchema } from "../lib";

/** Unique type discrimination for {@link Model} */
export const TYPE = "pillar3/A/closure";

/** Validation schema for {@link Model} */
export const schema = createSchema(TYPE, {
	fkPillar3AClosure: pillar3AClosureModelSchema.shape._id,
});
/** Movement group for an {@link pillar3AClosureDtoSchema} */
export type Model = z.infer<typeof schema>;

/** Validation schema for {@link Dto} */
export const dtoSchema = z.object({
	...schema.shape,
	pillar3AClosure: z.lazy(() => pillar3AClosureDtoSchema),
});
/**
 * Dto extension of {@link Model}.
 * Principally for query/filter purposes.
 */
export type Dto = z.infer<typeof dtoSchema>;
