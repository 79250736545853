import * as z from "zod";

import { stockClosureDtoSchema } from "../../../stock/closure/dtos/stock-closure.dto";
import { stockClosureModelSchema } from "../../../stock/closure/stock-closure.model";
import { createSchema } from "../lib";

/** Unique type discrimination for {@link Model} */
export const TYPE = "stock-closure";

/** Validation schema for {@link Model} */
export const schema = createSchema(TYPE, {
	fkStockClosure: stockClosureModelSchema.shape._id,
});
/** Movement Group representation of a {@link stockClosureModelSchema} */
export type Model = z.infer<typeof schema>;

/** Validation schema for {@link Dto} */
export const dtoSchema = z.object({
	...schema.shape,
	stockClosure: z.lazy(() => stockClosureDtoSchema),
});
/**
 * Dto extension of {@link Model}.
 * Principally for query/filter purposes.
 */
export type Dto = z.infer<typeof dtoSchema>;
