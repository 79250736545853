import * as z from "zod";

import { dtoSchema as pillar3BClosureDtoSchema } from "../../../pillar3/b/closure/dtos/dtos";
import { modelClosureSchema as pillar3BClosureModelSchema } from "../../../pillar3/b/closure/model";
import { createSchema } from "../lib";

/** Unique type discrimination for {@link Model} */
export const TYPE = "pillar3/B/closure";

/** Validation schema for {@link Model} */
export const schema = createSchema(TYPE, {
	fkPillar3BClosure: pillar3BClosureModelSchema.shape._id,
});
/** Movement group for an {@link pillar3BClosureModelSchema} */
export type Model = z.infer<typeof schema>;

/** Validation schema for {@link Dto} */
export const dtoSchema = z.object({
	...schema.shape,
	pillar3BClosure: z.lazy(() => pillar3BClosureDtoSchema),
});
/**
 * Dto extension of {@link Model}.
 * Principally for query/filter purposes.
 */
export type Dto = z.infer<typeof dtoSchema>;
