import * as z from "zod";

import {
	SourceAmountModel,
	sourceAmountModelRawShape,
} from "./source-amount.model";
import { createMultilineSchema } from "../common/multiline";

/** Validation schema for {@link SourceAmountActionCreate} */
export const sourceAmountActionCreateSchema = z.object({
	...sourceAmountModelRawShape,
}) satisfies z.ZodType<Pick<SourceAmountModel, "amount" | "source">>;
/** Payload for a 'create source-amount' action, used in {@link SourceAmountActions} */
export type SourceAmountActionCreate = z.infer<
	typeof sourceAmountActionCreateSchema
>;

/** Validation schema for {@link SourceAmountActionUpdate} */
const { source: _1, ...sourceAmountActionUpdateShape } =
	sourceAmountActionCreateSchema.shape;
export const sourceAmountActionUpdateSchema = z
	.object(sourceAmountActionUpdateShape)
	.partial();
/** Payload for an 'update source-amount' action, used in {@link SourceAmountActions} */
export type SourceAmountActionUpdate = z.infer<
	typeof sourceAmountActionUpdateSchema
>;

/** Validation schema for {@link SourceAmountActions} */
export const sourceAmountActionsSchema = createMultilineSchema(
	sourceAmountActionCreateSchema,
	sourceAmountActionUpdateSchema,
).default([]);
/**
 * "multiline" actions when handling source-amounts.
 * Used by the 'movement-groups'
 */
export type SourceAmountActions = z.infer<typeof sourceAmountActionsSchema>;
/** Single action from {@link SourceAmountActions} */
export type SourceAmountAction = SourceAmountActions[number];
