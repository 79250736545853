import * as z from "zod";

import { vestedBenefitsCreateDtoSchema } from "./vested-benefits.create.dto";

/** Validation schema for {@link VestedBenefitsUpdateDto} */
export const vestedBenefitsUpdateDtoSchema =
	vestedBenefitsCreateDtoSchema.partial();
/** Dto to update a `vestedBenefit` */
export type VestedBenefitsUpdateDto = z.infer<
	typeof vestedBenefitsUpdateDtoSchema
>;
