import * as z from "zod";

import { Schema } from "./model";
import * as States from "./states";

/**
 * Extract the fields schema for a `ChangeState` schema
 *
 * @param schema to extract the fields from
 * @returns the enum schema from the schema
 */
export function extractFieldsSchemaFromSchema<
	const S extends Schema<readonly string[]>,
>(schema: S): S["options"][2]["shape"]["fields"]["_def"]["type"] {
	// There should be a way to avoid this,
	//	but currently, without this, it inference string instead of the real value
	type OUT = S["options"][2]["shape"]["fields"]["_def"]["type"];

	// This type-checks that we are using the correct option
	const shape = schema.options[2].shape satisfies {
		type: z.ZodLiteral<typeof States.Updated.TYPE>;
	};

	// The property -> content of the array -> the enum
	return shape.fields._def.type as OUT;
}

/**
 * Extract the fields for a `ChangeState` schema
 *
 * @param schema to extract the fields from
 * @returns the fields options
 */
export function extractFieldsFromSchema<
	const S extends Schema<readonly string[]>,
>(schema: S) {
	const enumFields = extractFieldsSchemaFromSchema(schema);
	// CF above for the cast
	return enumFields.options as (typeof enumFields)["options"];
}
