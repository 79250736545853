import { HttpRoute } from "@nna/core";

import { CreateStockTransactionHttpDefinitions } from "./transaction";
import { type EntryHttpConfig } from "../entry.http";
import { CreateStockClosureHttpDefinitions } from "./closure";
import type {
	StockCreateDto,
	StockDto,
	StockQueryDto,
	StockQueryResultsDto,
	StockUpdateDto,
} from "./stock.dto";

/**
 * Create routes definitions for `Stock` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createStockHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const baseById = base.addSegment({
		param: "stockId",
		type: "param",
		validation: "number",
	});

	return {
		children: {
			/** Note: even if, for now, there is only one closure by stock, the plural is ready for V2 */
			closures: CreateStockClosureHttpDefinitions(
				baseById.addSegment("closures"),
			),
			transactions: CreateStockTransactionHttpDefinitions(
				baseById.addSegment("transactions"),
			),
		},
		routes: {
			findAndCount:
				base.get<
					(query: StockQueryDto) => Promise<StockQueryResultsDto>
				>(),
			findById: baseById.get<() => Promise<StockDto>>(),

			create: base.post<(body: StockCreateDto) => Promise<StockDto>>(),
			delete: baseById.delete<() => Promise<StockDto>>(),
			update: baseById.patch<
				(body: StockUpdateDto) => Promise<StockDto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

/** Full configuration for `Entry/Stock` features and children */
export type StockHttpConfig = EntryHttpConfig["children"]["stocks"];
/** HTTP specification for the `Entry/Stock` features */
export type StockHttp = HttpRoute.Handlers<StockHttpConfig["routes"]>;
