import { Model } from "@nna/core";
import * as z from "zod";

import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../../../entry.change-state";
import { EntryCommon } from "../../../../entry.common";
import { characteristicsModelSchema } from "../characteristics.model";

/** Zod Shape for {@link characteristicsRateModelSchema} */
export const characteristicsRateModelRawShape = {
	age: z.number().int().gte(17).lte(200),
	rateMale: EntryCommon.boundedPercentageSchema,
	rateFemale: EntryCommon.boundedPercentageSchema,
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link CharacteristicsRateModel} */
export const characteristicsRateModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(characteristicsRateModelRawShape),
		{
			...characteristicsRateModelRawShape,
			fkCharacteristics: characteristicsModelSchema.shape._id,
		},
	),
});

export type CharacteristicsRateModel = z.infer<
	typeof characteristicsRateModelSchema
>;
