import { HttpRoute } from "@nna/core";

import { EntryHttpConfig } from "../entry.http";
import {
	IndependentCreateDto,
	IndependentDto,
	IndependentQueryDto,
	IndependentQueryResultsDto,
	IndependentUpdateDto,
} from "./independent.dto";
import { createOperatingAccountHttpDefinitions } from "./operating-account";

/**
 * Create routes definitions for `Independent` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createIndependentHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const baseById = base.addSegment({
		param: "independentId",
		type: "param",
		validation: "number",
	});

	return {
		children: {
			operatingAccounts: createOperatingAccountHttpDefinitions(
				baseById.addSegment("operating-accounts"),
			),
		},
		routes: {
			findAndCount:
				base.get<
					(
						query: IndependentQueryDto,
					) => Promise<IndependentQueryResultsDto>
				>(),
			findById: baseById.get<() => Promise<IndependentDto>>(),

			create: base.post<
				(body: IndependentCreateDto) => Promise<IndependentDto>
			>(),
			delete: baseById.delete<() => Promise<IndependentDto>>(),
			update: baseById.patch<
				(body: IndependentUpdateDto) => Promise<IndependentDto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

/** Full configuration for `Entry/Independent` features and children */
export type IndependentHttpConfig = EntryHttpConfig["children"]["independents"];
/** HTTP specification for the `Entry/Independent` features */
export type IndependentHttp = HttpRoute.Handlers<
	IndependentHttpConfig["routes"]
>;
