import * as z from "zod";

import { dateCappedSchema } from "./date";

/** Options for {@link periodDateSchema} */
export interface PeriodDateOptions {
	/** The valid maximum date (for both modes) */
	maxDate?: Date;
	/** The valid minimum date (for both modes) */
	minDate?: Date;
}
/**
 * Create a validation schema for {@link PeriodDate}
 *
 * Note: when periodic, it does not check (yet) the order of the 2 dates (start < end)
 *
 * @param options to additional validation
 * @returns a validation schema
 */
export function periodDateSchema(options: PeriodDateOptions = {}) {
	const { maxDate, minDate } = options;

	const _base = dateCappedSchema({});
	const withMax = maxDate ? _base.max(maxDate) : _base;
	const dateSchema = minDate ? withMax.min(minDate) : withMax;

	return z.discriminatedUnion("periodic", [
		z.object({
			periodic: z.literal(false),

			date: dateSchema,
		}),
		z.object({
			periodic: z.literal(true),

			dateEnd: dateSchema,
			dateStart: dateSchema,
		}),
	]);
}

/** When an element can be a one-time event (with a single date) or during a time-range */
export type PeriodDate = z.infer<ReturnType<typeof periodDateSchema>>;

/** Sub-type of {@link PeriodDate} when the element is a single event (non-periodic) */
export type PeriodDateEvent = Extract<PeriodDate, { periodic: false }>;
/** Sub-type of {@link PeriodDate} when the element is periodic (with time-range) */
export type PeriodDatePeriodic = Extract<PeriodDate, { periodic: true }>;
