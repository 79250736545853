import { z } from "zod";

import { pillar3YieldModelSchema, pillar3YieldRawShape } from "./model";

export const yieldDtoSchema = pillar3YieldModelSchema;
export type YieldDto = z.infer<typeof yieldDtoSchema>;

export const yieldCreateDtoSchema = z.object(pillar3YieldRawShape);
export type YieldCreateDto = z.infer<typeof yieldCreateDtoSchema>;

export const yieldUpdateDtoSchema = yieldCreateDtoSchema.partial();
export type YieldUpdateDto = z.infer<typeof yieldUpdateDtoSchema>;
