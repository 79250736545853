import * as dateFns from "date-fns";

/**
 * Get the first or last date from an year
 *
 * @param year to get the date from
 * @param startOfYear if true, get the first day of the year, otherwise the last day
 * @returns the first day of the year
 */
export function fromYear(year: number, startOfYear = true) {
	return startOfYear
		? dateFns.startOfYear(dateFns.setYear(0, year))
		: dateFns.endOfYear(dateFns.setYear(0, year));
}

/**
 * Gets the last day before the given year starts
 *
 * @example
 * getLastDayOfPreviousYear(2011) => `2010-12-31`
 */
export function getLastDayBeforeYear(year: number) {
	const asDate = fromYear(year);
	return dateFns.lastDayOfYear(dateFns.addYears(asDate, -1));
}
