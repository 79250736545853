import { HttpRoute } from "@nna/core";

import type {
	VestedBenefitClosureCreateDto,
	VestedBenefitClosureDto,
	VestedBenefitClosureUpdateDto,
} from "./dtos";
import { EntryHttpConfig } from "../../../entry.http";

/**
 * Create routes definitions for `VestedBenefitClosure` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createVestedBenefitClosureHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const baseById = base.addSegment({
		param: "closureId",
		type: "param",
		validation: "number",
	});

	return {
		children: {},
		routes: {
			create: base.post<
				(
					body: VestedBenefitClosureCreateDto,
				) => Promise<VestedBenefitClosureDto>
			>(),
			delete: baseById.delete<() => Promise<VestedBenefitClosureDto>>(),
			update: baseById.patch<
				(
					body: VestedBenefitClosureUpdateDto,
				) => Promise<VestedBenefitClosureDto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

/** Full configuration for `Entry/VestedBenefitClosure` features and children */
export type VestedBenefitClosureHttpConfig =
	EntryHttpConfig["children"]["pillar2"]["children"]["vestedBenefits"]["children"]["closures"];
/** HTTP specification for the `Entry/VestedBenefitClosure` features */
export type VestedBenefitClosureHttp = HttpRoute.Handlers<
	VestedBenefitClosureHttpConfig["routes"]
>;
