import { HttpRoute } from "@nna/core";

import type { Pillar2HttpConfig } from "../pillar2.http";
import type * as DTOs from "./dtos";
import { createPillar2AffiliationPlanHttpDefinitions } from "./plan/pillar2.affiliation-plan.http";

/**
 * Create routes definitions for `Pillar2/Affiliation` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createPillar2AffiliationHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const baseById = base.addSegment({
		param: "pillar2AffiliationId",
		type: "param",
		validation: "number",
	});

	return {
		children: {
			plans: createPillar2AffiliationPlanHttpDefinitions(
				baseById.addSegment("plans"),
			),
		},
		routes: {
			findAndCount:
				base.get<
					(
						query: DTOs.Pillar2AffiliationQueryDto,
					) => Promise<DTOs.Pillar2AffiliationQueryResultsDto>
				>(),
			findById: baseById.get<() => Promise<DTOs.Pillar2AffiliationDto>>(),

			create: base.post<
				(
					body: DTOs.Pillar2AffiliationCreateDto,
				) => Promise<DTOs.Pillar2AffiliationDto>
			>(),
			delete: baseById.delete<
				() => Promise<DTOs.Pillar2AffiliationDto>
			>(),
			update: baseById.patch<
				(
					body: DTOs.Pillar2AffiliationUpdateDto,
				) => Promise<DTOs.Pillar2AffiliationDto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

/** Full configuration for `Entry/Pillar2/Affiliation` features and children */
export type Pillar2AffiliationHttpConfig =
	Pillar2HttpConfig["children"]["affiliations"];
/** HTTP specification for the `Entry/Pillar2/Affiliation` features */
export type Pillar2AffiliationHttp = HttpRoute.Handlers<
	Pillar2AffiliationHttpConfig["routes"]
>;
