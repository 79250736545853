import * as z from "zod";

import { accountMovementSchema } from "../../../account/account.movement";
import { MovementData } from "../../../movement";

const { Values: kind } = MovementData.Options.Pillar2AffiliationPlan.kindSchema;

/** Validation schema for {@link Pillar2AffiliationPlanMovementDirection} */
export const pillar2AffiliationPlanMovementDirectionSchema = z
	.enum([
		// For 'EPL' conversion
		"EPL_in", // "Remboursement"
		"EPL_out", // "Retrait"

		// For 'divorce-allowance' conversion
		"divorce-allowance_in", // "recue"
		"divorce-allowance_out", // "versée"

		// For theses, it has only as direction of "in"
		kind["buyback_ordinary"],
		kind["buyback_early-retirement"],
		kind["buyback_AVS-bridge"],
		kind["buyback_divorce"],
	])
	.describe(
		"Define the direction of the movement with a pillar2/affiliation/plan",
	);
export type Pillar2AffiliationPlanMovementDirection = z.infer<
	typeof pillar2AffiliationPlanMovementDirectionSchema
>;

/** Schema for {@link Pillar2AffiliationPlanMovement} */
export const pillar2AffiliationPlanMovementSchema = z.object({
	...accountMovementSchema.shape,
	direction: pillar2AffiliationPlanMovementDirectionSchema,
});
/** To manipulate movement from or out of a pillar2/affiliation/plan */
export type Pillar2AffiliationPlanMovement = z.infer<
	typeof pillar2AffiliationPlanMovementSchema
>;

/** Output of {@link getPillar2AffiliationPlanMovementDirectionInfo} */
export interface Pillar2AffiliationPlanMovementDirectionInfo {
	/** From the direction, is the 'plan of the affiliation' the input (`to` property) */
	asInput: boolean;
	/** The conversions */
	kind: MovementData.Options.Pillar2AffiliationPlan.Kind;
}
/** Get "movement" information from {@link Pillar2AffiliationPlanMovementDirection} */
export function getPillar2AffiliationPlanMovementDirectionInfo(
	direction: Pillar2AffiliationPlanMovementDirection,
): Pillar2AffiliationPlanMovementDirectionInfo {
	// Note: this could be replaced by a "convert-object"

	switch (direction) {
		case "EPL_in":
			return { asInput: true, kind: "EPL" };
		case "EPL_out":
			return { asInput: false, kind: "EPL" };

		case "divorce-allowance_in":
			return { asInput: true, kind: "divorce-allowance" };
		case "divorce-allowance_out":
			return { asInput: false, kind: "divorce-allowance" };

		case "buyback_ordinary":
		case "buyback_early-retirement":
		case "buyback_AVS-bridge":
		case "buyback_divorce":
			return { asInput: true, kind: direction };
	}
}
/**
 * Opposite of {@link getPillar2AffiliationPlanMovementDirectionInfo}:
 * Get the direction from info
 */
export function getPillar2AffiliationPlanMovementDirectionFromInfo(
	info: Pillar2AffiliationPlanMovementDirectionInfo,
): Pillar2AffiliationPlanMovementDirection {
	const { asInput, kind } = info;
	switch (kind) {
		case "EPL":
			return asInput ? "EPL_in" : "EPL_out";
		case "divorce-allowance":
			return asInput ? "divorce-allowance_in" : "divorce-allowance_out";

		case "buyback_ordinary":
		case "buyback_early-retirement":
		case "buyback_AVS-bridge":
		case "buyback_divorce":
			return kind;
	}
}
