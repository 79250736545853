import { HttpRoute } from "@nna/core";

import {
	OperatingAccountCreateDto,
	OperatingAccountDto,
	OperatingAccountQueryDto,
	OperatingAccountQueryResultsDto,
	OperatingAccountUpdateDto,
} from "./operating-account.dto";
import { IndependentHttpConfig } from "../independent.http";

/**
 * Create routes definitions for `Operating Account` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createOperatingAccountHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const baseById = base.addSegment({
		param: "operatingAccountId",
		type: "param",
		validation: "number",
	});

	return {
		children: {},
		routes: {
			findAndCount:
				base.get<
					(
						query: OperatingAccountQueryDto,
					) => Promise<OperatingAccountQueryResultsDto>
				>(),
			findById: baseById.get<() => Promise<OperatingAccountDto>>(),

			create: base.post<
				(
					body: OperatingAccountCreateDto,
				) => Promise<OperatingAccountDto>
			>(),
			delete: baseById.delete<() => Promise<OperatingAccountDto>>(),
			update: baseById.patch<
				(
					body: OperatingAccountUpdateDto,
				) => Promise<OperatingAccountDto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

/** Full configuration for `Entry/Independent/OperatingAccount` features and children */
export type OperatingAccountHttpConfig =
	IndependentHttpConfig["children"]["operatingAccounts"];
/** HTTP specification for the `Entry/Independent/OperatingAccount` features */
export type OperatingAccountHttp = HttpRoute.Handlers<
	OperatingAccountHttpConfig["routes"]
>;
