import * as z from "zod";

import { sourceAmountDtoSchema } from "../../../../../source-amount/source-amount.model";
import { pillar2AffiliationPlanClosureModelSchema } from "../pillar2.affiliation-plan-closure.model";

/** Validation schema for {@link Pillar2AffiliationPlanClosureDto} */
export const pillar2AffiliationPlanClosureDtoSchema = z.object({
	...pillar2AffiliationPlanClosureModelSchema.shape,
	funds: z.array(z.lazy(() => sourceAmountDtoSchema)),
});
export type Pillar2AffiliationPlanClosureDto = z.infer<
	typeof pillar2AffiliationPlanClosureDtoSchema
>;
