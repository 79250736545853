import { Model } from "@nna/core";
import * as z from "zod";

import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../entry.change-state";
import { entryModelSchema } from "../../entry.model";

/** Zod Shape for {@link pillar2PlanModelSchema} */
export const pillar2PlanModelRawShape = {
	name: z.string().min(2),
	elitePlan: z.boolean().describe("Is this an Elite plan"),
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link RecipeModel} */
export const pillar2PlanModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(pillar2PlanModelRawShape),
		{
			...pillar2PlanModelRawShape,
			fkEntry: entryModelSchema.shape._id,
		},
	),
});
/** A "plan" for the Pillar 2 is like a configuration to be used in affiliation */
export type Pillar2PlanModel = z.infer<typeof pillar2PlanModelSchema>;
