import * as dateFns from "date-fns";

import { CustomerModel } from "../../../customer";
import { CustomerSingle } from "../../common";
import { PersonalCreateDto, PersonalDto } from "../dtos";
import { confessionSchema, PersonalModel } from "../personal.model";

/**
 * Returns a PersonalDto, if found, based on a list of Personals for a given customerSingle
 *
 * @param personals The list of Personals to search
 * @param customerSingle The customerSingle to search for
 * @returns The PersonalDto, if found
 */
export function getPersonal(
	personals: PersonalModel[],
	customerSingle: CustomerSingle,
): PersonalDto | undefined {
	return personals.find(
		({ customer: currentCustomerSingle }) =>
			currentCustomerSingle === customerSingle,
	);
}

/**
 * Computes the retirement date of a customer based on its birthday and the legal retirement age.
 *
 * Retirement date is the last day of the month in which the customer turns 65.
 *
 * @param customer The customer to compute the retirement datePersonal for
 * @param retirementAge The age of retirement
 * @returns a Date object representing the retirement date (UTC, midnight) of the user
 */
export function computeRetirementDate(
	customer: Pick<CustomerModel, "birthday">,
	retirementAge = 65,
) {
	const baseDate = dateFns.addYears(customer.birthday, retirementAge);

	// The 'dateFns' function does the same as
	//	[https://stackoverflow.com/questions/222309/calculate-last-day-of-month], using day-0 of a month returns last day of the previous month!
	return dateFns.lastDayOfMonth(baseDate);
}

/**
 * Builds a default Personal object, that is used to create a new Personal
 *
 * @param customer The customer to create the Personal for
 * @param customerSingle The customerSingle to create the Personal for
 * @param retirementAge The retirement age to base the retirement date on @default 65 years old
 * @returns The default PersonalCreateDto object
 */
export function getDefaultPersonal(
	customer: CustomerModel,
	customerSingle: CustomerSingle,
	retirementAge = 65,
): PersonalCreateDto {
	return {
		confession: confessionSchema.Values["sans confession"],
		customer: customerSingle,
		retirement: computeRetirementDate(customer, retirementAge),
	};
}
