import * as z from "zod";

import { createSchema } from "../lib";

/** Unique type discrimination for {@link Model} */
export const TYPE = "pillar2/affiliation-plan";

/** Validation schema for {@link Kind} */
export const kindSchema = z.enum([
	"EPL",
	"divorce-allowance",

	// The following ones only makes sens when the `to` source is a plan of a pillar2/affiliation
	"buyback_ordinary", // "Rachat ordinaire"
	"buyback_early-retirement", // "Rachat retraite anticipée"
	"buyback_AVS-bridge", // "Rachat rente pont AVS"
	"buyback_divorce", // "Rachat cas divorce"
]);
/** The possible kind ("sub-type") of movement for {@link Model} */
export type Kind = z.infer<typeof kindSchema>;

/** Validation schema for {@link Model} */
export const schema = createSchema(TYPE, {
	kind: kindSchema.describe('"Sub-type" of the movement'),
});
/** Extra data for a movement with a 'plan of a pillar 2 affiliation' source */
export type Model = z.infer<typeof schema>;
