import { Model } from "@nna/core";
import * as z from "zod";

import { dateCappedSchema } from "../common";
import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../entry.change-state";
import { EntryCommon } from "../entry.common";
import { entryModelSchema } from "../entry.model";

/** Zod Shape for {@link independentModelSchema} */
export const independentModelRawShape = {
	name: z.string().min(2),
	entitled: EntryCommon.customerSingleSchema,
	postcode: z.string().min(4).max(4),
	city: z.string().min(2),
	dateStart: dateCappedSchema({}),
	equityAccounting: z.number(),
	equityReal: z.number(),
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link IndependentModel} */
export const independentModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(independentModelRawShape),
		{
			...independentModelRawShape,
			fkEntry: entryModelSchema.shape._id,
		},
	),
});

/** Independent model (1st level) */
export type IndependentModel = z.infer<typeof independentModelSchema>;
