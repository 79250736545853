import { z } from "zod";

import { pillar3YieldModelSchema } from "../../common";
import { modelSchema } from "../model";

export const pillar3AYieldModelSchema = pillar3YieldModelSchema.extend({
	fkPillar3A: modelSchema.shape._id,
});

export type YieldModel = z.infer<typeof pillar3AYieldModelSchema>;
