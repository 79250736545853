import * as z from "zod";

import { sourceSchema } from "../../../source";

/**
 * The kind of reference to use:
 * - custom: "free text"
 * - source: link with {@link sourceSchema}
 * - option: select option within enumeration
 */
export const STATEMENT_LINE_REFERENCE_KIND_KEY = "kind";

/** Validation schema for {@link StatementLineKindCustomModel} */
export const statementLineKindCustomSchema = z.object({
	[STATEMENT_LINE_REFERENCE_KIND_KEY]: z.literal("custom"),
	value: z.string().min(1),
});
/** A {@link StatementLineKindCustomModel} that uses a custom value ("free text") */
export type StatementLineKindCustomModel = z.infer<
	typeof statementLineKindCustomSchema
>;

/** Validation schema for {@link StatementLineKindSourceModel} */
export const statementLineKindSourceSchema = z.object({
	[STATEMENT_LINE_REFERENCE_KIND_KEY]: z.literal("source"),
	source: sourceSchema,
});
/** A {@link StatementLineKindSourceModel} that links a {@link sourceSchema} */
export type StatementLineKindSourceModel = z.infer<
	typeof statementLineKindSourceSchema
>;

/** To create {@link StatementLineKindOptionModel} */
function createStatementLineKindOptionSchema<
	const T extends readonly [string, ...string[]],
>(options: T) {
	return z.object({
		[STATEMENT_LINE_REFERENCE_KIND_KEY]: z.literal("option"),
		option: z.enum(options),
	});
}

function createStatementLineKindSchema<
	const T extends readonly [string, ...string[]],
>(options: T) {
	return z.discriminatedUnion(STATEMENT_LINE_REFERENCE_KIND_KEY, [
		statementLineKindCustomSchema,
		statementLineKindSourceSchema,
		createStatementLineKindOptionSchema(options),
	]);
}

// OPERATING LINE TYPE SCHEMAS
/** Whether the statement line is a revenue, ... */
export const STATEMENT_LINE_TYPE_KEY = "type";

export const STATEMENT_LINE_KIND_REVENUE_OPTIONS = [
	"Honoraires",
	"Autres produits",
] as const;
/** Validation schema for {@link StatementLineTypeRevenueModel} */
export const statementLineTypeRevenueSchema = z.object({
	[STATEMENT_LINE_TYPE_KEY]: z.literal("revenue"),
	kind: createStatementLineKindSchema(STATEMENT_LINE_KIND_REVENUE_OPTIONS),
});
export type StatementLineTypeRevenueModel = z.infer<
	typeof statementLineTypeRevenueSchema
>;

export const STATEMENT_LINE_KIND_COST_REVENUE_OPTIONS = [
	"Prix de revient marchandises",
	"Achats de prestations",
] as const;
/** Validation schema for {@link StatementLineTypeCostRevenueModel} */
export const statementLineTypeCostRevenueSchema = z.object({
	[STATEMENT_LINE_TYPE_KEY]: z.literal("cost-revenue"),
	kind: createStatementLineKindSchema(
		STATEMENT_LINE_KIND_COST_REVENUE_OPTIONS,
	),
});
export type StatementLineTypeCostRevenueModel = z.infer<
	typeof statementLineTypeCostRevenueSchema
>;

export const STATEMENT_LINE_KIND_EXPENSES_HR_OPTIONS = [
	"Réserve Fluctuation valeurs (RFV)",
	"Salaires du personnel",
	"Charges Sociales du personnel",
	"Frais de personnel",
	"Réserve contribution employeur (RCE)",
	"Perte de gain Indépendant",
] as const;
/** Validation schema for {@link StatementLineTypeExpensesHRModel} */
export const statementLineTypeExpensesHRSchema = z.object({
	[STATEMENT_LINE_TYPE_KEY]: z.literal("expenses-hr"),
	kind: createStatementLineKindSchema(
		STATEMENT_LINE_KIND_EXPENSES_HR_OPTIONS,
	),
});
export type StatementLineTypeExpensesHRModel = z.infer<
	typeof statementLineTypeExpensesHRSchema
>;

export const STATEMENT_LINE_KIND_EXPENSES_OTHER_OPTIONS = [
	"Frais généraux",
	"Autres Charges",
] as const;
/** Validation schema for {@link StatementLineTypeExpensesOtherModel} */
export const statementLineTypeExpensesOtherSchema = z.object({
	[STATEMENT_LINE_TYPE_KEY]: z.literal("expenses-other"),
	kind: createStatementLineKindSchema(
		STATEMENT_LINE_KIND_EXPENSES_OTHER_OPTIONS,
	),
});
export type StatementLineTypeExpensesOtherModel = z.infer<
	typeof statementLineTypeExpensesOtherSchema
>;

export const STATEMENT_LINE_KIND_EXPENSES_FINANCIAL_OPTIONS = [
	"Amortissement usuels",
	"Amortissement à caractère Provision",
	"Intérêts bancaires",
	"Frais bancaires",
] as const;
/** Validation schema for {@link StatementLineTypeExpensesFinancialModel} */
export const statementLineTypeExpensesFinancialSchema = z.object({
	[STATEMENT_LINE_TYPE_KEY]: z.literal("expenses-financial"),
	kind: createStatementLineKindSchema(
		STATEMENT_LINE_KIND_EXPENSES_FINANCIAL_OPTIONS,
	),
});
export type StatementLineTypeExpensesFinancialModel = z.infer<
	typeof statementLineTypeExpensesFinancialSchema
>;

/** Validation schema for {@link StatementLineTypeModel} */
export const statementLineTypeModelSchema = z.discriminatedUnion(
	STATEMENT_LINE_TYPE_KEY,
	[
		statementLineTypeRevenueSchema,
		statementLineTypeCostRevenueSchema,
		statementLineTypeExpensesHRSchema,
		statementLineTypeExpensesOtherSchema,
		statementLineTypeExpensesFinancialSchema,
	],
);
export type StatementLineTypeModel = z.infer<
	typeof statementLineTypeModelSchema
>;

export type StatementLineTypeType = StatementLineTypeModel["type"];
export const STATEMENT_LINE_TYPE_TYPES: readonly StatementLineTypeType[] =
	statementLineTypeModelSchema.options.map(({ shape }) => shape.type.value);

/** The kind property */
export type StatementLineKindModel = StatementLineTypeModel["kind"];
/** The value for a kind */
export type StatementLineKindKind = StatementLineKindModel["kind"];
export const STATEMENT_LINE_KIND_TYPES: readonly StatementLineKindKind[] =
	statementLineTypeRevenueSchema.shape.kind.options.map(
		({ shape }) => shape.kind.value,
	);

export type StatementLineKindOptionModel = Extract<
	StatementLineKindModel,
	{ kind: "option" }
>;
/** A {@link StatementLineKindOptionModel} with any of its options */
export interface StatementLineKindAnyOption
	extends Pick<StatementLineKindOptionModel, "kind"> {
	// TODO: delete and keep only in backend?
	option: StatementLineKindOptionOption;
}

/** All known option from {@link StatementLineKindOptionModel} */
export type StatementLineKindOptionOption =
	StatementLineKindOptionModel["option"];
export const STATEMENT_LINE_KIND_OPTION_OPTIONS: readonly StatementLineKindOptionOption[] =
	[
		// Better: extract from the final schema
		...STATEMENT_LINE_KIND_REVENUE_OPTIONS,
		...STATEMENT_LINE_KIND_COST_REVENUE_OPTIONS,
		...STATEMENT_LINE_KIND_EXPENSES_HR_OPTIONS,
		...STATEMENT_LINE_KIND_EXPENSES_OTHER_OPTIONS,
		...STATEMENT_LINE_KIND_EXPENSES_FINANCIAL_OPTIONS,
	];

/** From a type, get the expected kind */
export type StatementLineKindFromStatementTypeType<
	T extends StatementLineTypeType,
> = Extract<StatementLineTypeModel, { type: T }>["kind"];
/** From a type, get the expected kind {@link StatementLineKindOptionOption} */
export type StatementLineKindOptionFromStatementTypeType<
	T extends StatementLineTypeType,
> = Extract<
	StatementLineKindFromStatementTypeType<T>,
	{ kind: "option" }
>["option"];
