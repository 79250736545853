import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import {
	characteristicsRateModelRawShape,
	characteristicsRateModelSchema,
} from "./characteristics.rate.model";

/** Validation schema for {@link CharacteristicsRateDto} */
export const characteristicsRateDtoSchema = characteristicsRateModelSchema;
export type CharacteristicsRateDto = z.infer<
	typeof characteristicsRateDtoSchema
>;

/** Validation schema for {@link CharacteristicsRateCreateDto} */
export const characteristicsRateCreateDtoSchema = z.object(
	characteristicsRateModelRawShape,
);
/** Dto to create a `contribution` */
export type CharacteristicsRateCreateDto = z.infer<
	typeof characteristicsRateCreateDtoSchema
>;

/** Validation schema for {@link CharacteristicsRateQueryDto} */
export const characteristicsRateQueryDtoSchema = createQueryObjectSchema(
	characteristicsRateDtoSchema,
	{
		coerce: true,
		strict: true,
	},
).strict();

/** DTO used to filter `characteristicsRate` */
export type CharacteristicsRateQueryDto = z.infer<
	typeof characteristicsRateQueryDtoSchema
>;

/** Validation schema for {@link CharacteristicsRateQueryResultsDto} */
export const characteristicsRateQueryResultsDtoSchema =
	createQueryResultsSchema(characteristicsRateDtoSchema);
/** Results for `contribution` from a query */
export type CharacteristicsRateQueryResultsDto = z.infer<
	typeof characteristicsRateQueryResultsDtoSchema
>;

/** Validation schema for {@link CharacteristicsRateUpdateDto} */
export const characteristicsRateUpdateDtoSchema =
	characteristicsRateCreateDtoSchema.partial();
/** Dto to update a `contribution` */
export type CharacteristicsRateUpdateDto = z.infer<
	typeof characteristicsRateUpdateDtoSchema
>;
