import { Model } from "@nna/core";
import * as z from "zod";

import { dateCappedSchema } from "../../../common/date";
import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../../entry.change-state";
import { EntryCommon } from "../../../entry.common";
import { pillar2PlanModelSchema } from "../pillar2.plan.model";

export const CHARACTERISTICS_EQUAL_TO_AVS_SALARY_KEY = "equalToAVSSalary";

export const characteristicsRiskSalaryNotEqualAVSSchema = z.object({
	[CHARACTERISTICS_EQUAL_TO_AVS_SALARY_KEY]: z.literal(0),
	amountRiskCoordination: EntryCommon.amountSchema,
	capRisk: EntryCommon.amountSchema,
});
export const characteristicsRiskSalaryEqualAVSSchema = z.object({
	[CHARACTERISTICS_EQUAL_TO_AVS_SALARY_KEY]: z.literal(1),
});

export const characteristicsRiskSchema = z.discriminatedUnion(
	CHARACTERISTICS_EQUAL_TO_AVS_SALARY_KEY,
	[
		characteristicsRiskSalaryEqualAVSSchema,
		characteristicsRiskSalaryNotEqualAVSSchema,
	],
);

export type CharacteristicsRiskSalaryNotEqualAVSModel = z.infer<
	typeof characteristicsRiskSalaryNotEqualAVSSchema
>;
export type CharacteristicsRiskSalaryEqualAVSModel = z.infer<
	typeof characteristicsRiskSalaryEqualAVSSchema
>;
export type CharacteristicsRiskModel = z.infer<
	typeof characteristicsRiskSchema
>;

/**
 * Zod Shape for {@link characteristicsModelSchema}
 *
 * Important note: this shape matches the characteristics model for non-elite plan.
 * For elite plan, the model SHOULD be different – but it can actually be the same,
 * because the hidden fields are booleans that are defined in the initialValues anyway.
 * TODO: FG-538: when implementing Elite plans, check if this is still valid.
 */
export const characteristicsModelRawShape = {
	dateStart: dateCappedSchema({}).describe(
		"Start date of this characteristics",
	),
	capAmount: EntryCommon.amountSchema,
	minimumCoordinatedSalary: z.boolean().nullable(),
	coordinationAmount: EntryCommon.amountSchema,
	basedOnWorkHours: z.boolean(),
	risk: characteristicsRiskSchema,
	interestRateAssetsRevenue: EntryCommon.boundedPercentageSchema,
	interestRateRedemptions: EntryCommon.boundedPercentageSchema
		.nullable()
		.or(z.literal("").transform(() => null)), // FORMIK_456: To handle Formik empty string state
	equalContributions: z.boolean(),
	equalConversionRate: z.boolean(),
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link CharacteristicsModel} */
export const characteristicsModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(characteristicsModelRawShape),
		{
			...characteristicsModelRawShape,
			fkPillar2Plan: pillar2PlanModelSchema.shape._id,
		},
	),
});
/** A "characteristics" for the Pillar 2 plan */
export type CharacteristicsModel = z.infer<typeof characteristicsModelSchema>;
