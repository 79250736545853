import * as z from "zod";

import { createClosureModelSchema } from "../../../common";
import { vestedBenefitsModelSchema } from "../vested-benefits.model";

export const vestedBenefitClosureTypeSchema = z.enum(["RETIREMENT", "CLOSURE"]);
export type VestedBenefitClosureType = z.infer<
	typeof vestedBenefitClosureTypeSchema
>;

/** Validation schema for {@link VestedBenefitClosureModel} */
export const vestedBenefitClosureModelSchema = createClosureModelSchema(
	{ closureType: vestedBenefitClosureTypeSchema },
	{ fkVestedBenefit: vestedBenefitsModelSchema.shape._id },
);
export type VestedBenefitClosureModel = z.infer<
	typeof vestedBenefitClosureModelSchema
>;
