import * as z from "zod";

import { sourceAmountDtoSchema } from "../../../../source-amount/source-amount.model";
import { vestedBenefitClosureModelSchema } from "../vested-benefit-closure.model";

/** Validation schema for {@link VestedBenefitClosureDto} */
export const vestedBenefitClosureDtoSchema = z.object({
	...vestedBenefitClosureModelSchema.shape,
	funds: z.array(z.lazy(() => sourceAmountDtoSchema)),
});
export type VestedBenefitClosureDto = z.infer<
	typeof vestedBenefitClosureDtoSchema
>;
