import * as z from "zod";

import { creditModelSchema } from "../../credit/credit.model";
import { createSchema } from "../lib";

/** Unique type discrimination for {@link Model} */
export const TYPE = "credit";

/** Validation schema for {@link Model} */
export const schema = createSchema(TYPE, {
	fkCredit: creditModelSchema.shape._id,
});
/** Movement source for an {@link creditModelSchema} */
export type Model = z.infer<typeof schema>;

/** Validation schema for {@link Dto} */
export const dtoSchema = z.object({
	...schema.shape,
	credit: z.lazy(() => creditModelSchema), // FIXME: we import the model, because the dto fails with a circular import error
});
/**
 * Dto extension of {@link Model}.
 * Principally for query/filter purposes.
 */
export type Dto = z.infer<typeof dtoSchema>;
