import { styled } from "@mui/joy";

import { Checkbox } from "../../ui-form/components/Checkbox";
import { textH5 } from "../styles/textStyles";

/** Props for {@link SectionSubtitle} */
export interface SectionSubtitleProps {
	/** Actions (typically an array of Buttons) displayed at the right of the title bar */
	actions?: React.ReactNode;
	/** If [[showActivation]] is enabled, is the checkbox checked? */
	isActive?: boolean;

	/** If [[showActivation]] is enabled, the function called when the checkbox is checked/unchecked */
	onActivationChange?: (status: boolean) => void;

	/**
	 * Whether to display the checkbox at the left of the title bar or not
	 *
	 * @default false
	 */
	showActivation?: boolean;

	/** The section title */
	title: string;
}

/**
 * A section subtitle (level 4)
 *
 * @param props to generate the component
 */
export const SectionSubtitle = (props: SectionSubtitleProps) => {
	const { actions, isActive, onActivationChange, showActivation, title } =
		props;

	return (
		<SectionTitleContainer>
			{showActivation && (
				<Checkbox
					value={isActive ? 1 : 0}
					onChange={event =>
						onActivationChange?.(event.target.checked)
					}
				/>
			)}
			<h4 css={textH5}>{title}</h4>
			<ActionsContainer>{actions}</ActionsContainer>
		</SectionTitleContainer>
	);
};

const SectionTitleContainer = styled("div")`
	align-items: center;
	border-bottom: 1px solid ${({ theme }) => theme.palette.grey[40]};
	display: flex;
	gap: 4px;
	padding: 12px;
`;

const ActionsContainer = styled("div")`
	display: flex;
	flex-wrap: no-wrap;
	gap: 8px;
	margin-left: auto;
`;
