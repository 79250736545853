import { Model } from "@nna/core";
import * as z from "zod";

import { dateCappedSchema } from "../../../common";
import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../../entry.change-state";

export const paymentReleaseRawShape = {
	dateStart: dateCappedSchema({}).describe("Start date of payment release"),
	dateEnd: dateCappedSchema({}).describe("End date of payment release"),
};

export const paymentReleaseSchema = z.object(paymentReleaseRawShape);
export type PaymentRelease = z.infer<typeof paymentReleaseSchema>;

export const paymentReleaseModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(paymentReleaseRawShape),
		{
			...paymentReleaseRawShape,
		},
	),
});

export type PaymentReleaseModel = z.infer<typeof paymentReleaseModelSchema>;
