import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import {
	stockValueEvolutionModelSchema,
	stockValueEvolutionRawShape,
} from "./stock-value-evolution.model";

/** Validation schema for {@link StockValueEvolutionDto} */
export const stockValueEvolutionDtoSchema = stockValueEvolutionModelSchema;

export type StockValueEvolutionDto = z.infer<
	typeof stockValueEvolutionDtoSchema
>;

/** Validation schema for {@link stockValueEvolutionCreateDto} */
export const stockValueEvolutionCreateDtoSchema = z.object(
	stockValueEvolutionRawShape,
);
/** Dto to create a `stock */
export type StockValueEvolutionCreateDto = z.infer<
	typeof stockValueEvolutionCreateDtoSchema
>;

/** Validation schema for {@link stockValueEvolutionQueryDto} */
export const stockValueEvolutionQueryDtoSchema = createQueryObjectSchema(
	stockValueEvolutionDtoSchema,
	{
		coerce: true,
		strict: true,
	},
).strict();

/** DTO used to filter `stockValueEvolution` */
export type StockValueEvolutionQueryDto = z.infer<
	typeof stockValueEvolutionQueryDtoSchema
>;

/** Validation schema for {@link stockValueEvolutionQueryResultsDto} */
export const stockValueEvolutionQueryResultsDtoSchema =
	createQueryResultsSchema(stockValueEvolutionDtoSchema);
/** Results for `stocks` from a query */
export type StockValueEvolutionQueryResultsDto = z.infer<
	typeof stockValueEvolutionQueryResultsDtoSchema
>;

/** Validation schema for {@link stockValueEvolutionUpdateDto} */
export const stockValueEvolutionUpdateDtoSchema =
	stockValueEvolutionCreateDtoSchema.partial();
/** Dto to update a `stock */
export type StockValueEvolutionUpdateDto = z.infer<
	typeof stockValueEvolutionUpdateDtoSchema
>;
