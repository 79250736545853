import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import { createMultilineSchema } from "../common/multiline";
import { stockClosureDtoSchema } from "./closure/dtos";
import {
	stockDividendCreateDtoSchema,
	stockDividendDtoSchema,
	stockDividendUpdateDtoSchema,
} from "./dividend";
import { stockModelRawShape, stockModelSchema } from "./stock.model";
import {
	stockValueEvolutionCreateDtoSchema,
	stockValueEvolutionDtoSchema,
	stockValueEvolutionUpdateDtoSchema,
} from "./value-evolution";
import {
	sourceAmountActionsSchema,
	sourceAmountDtoSchema,
} from "../source-amount";
import { stockDtoBaseSchema } from "./stock-base.dto";
import { stockTransactionDtoSchema } from "./transaction";

/** Validation schema for {@link StockDto} */
export const stockDtoSchema = z.object({
	...stockDtoBaseSchema.shape,

	// TODO: transactions impl
	// transactions: z.array(z.lazy(() => stockTransactionDtoSchema)),

	closure: z
		.lazy(() => stockClosureDtoSchema)
		.nullable()
		.describe("The closure of this stock.<br>`null` when there is none"),

	fundsSource: z
		.array(z.lazy(() => sourceAmountDtoSchema))
		.describe(
			"The 'provenance de fonds'.<br/>Only supposed to be used if the account is 'future'",
		),

	transactions: z.array(z.lazy(() => stockTransactionDtoSchema)),

	marketValueEvolutions: z.array(stockValueEvolutionDtoSchema),
	fiscalValueEvolutions: z.array(stockValueEvolutionDtoSchema),

	dividends: z.array(stockDividendDtoSchema),
});
export type StockDto = z.infer<typeof stockDtoSchema>;

/** Validation schema for {@link StockCreateDto} */
export const stockCreateDtoSchema = z
	.object({
		...stockModelRawShape,
		dividends: createMultilineSchema(
			stockDividendCreateDtoSchema,
			stockDividendUpdateDtoSchema,
		),
		marketValueEvolutions: createMultilineSchema(
			stockValueEvolutionCreateDtoSchema,
			stockValueEvolutionUpdateDtoSchema,
		),
		fiscalValueEvolutions: createMultilineSchema(
			stockValueEvolutionCreateDtoSchema,
			stockValueEvolutionUpdateDtoSchema,
		),
		fundsSource: z.lazy(() => sourceAmountActionsSchema),
	})
	.strict();
/** Dto to create a `stock */
export type StockCreateDto = z.infer<typeof stockCreateDtoSchema>;

/**
 * Validation schema for {@link StockQueryDto}
 * Note that we use 'stockModelSchema' here, because using the 'stockDtoSchema' with interests
 * is causing a ts(2589) issue in the backend payload file.
 */
export const stockQueryDtoSchema = createQueryObjectSchema(stockModelSchema, {
	coerce: true,
	strict: true,
}).strict();

/** DTO used to filter `stock` */
export type StockQueryDto = z.infer<typeof stockQueryDtoSchema>;

/** Validation schema for {@link StockQueryResultsDto} */
export const stockQueryResultsDtoSchema =
	createQueryResultsSchema(stockDtoSchema);
/** Results for `stocks` from a query */
export type StockQueryResultsDto = z.infer<typeof stockQueryResultsDtoSchema>;

/** Validation schema for {@link StockUpdateDto} */
export const stockUpdateDtoSchema = stockCreateDtoSchema.partial();
/** Dto to update a `stock */
export type StockUpdateDto = z.infer<typeof stockUpdateDtoSchema>;
