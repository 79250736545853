import * as z from "zod";

import {
	vestedBenefitClosureDtoSchema,
	vestedBenefitClosureModelSchema,
} from "../../../pillar2";
import { createSchema } from "../lib";

/** Unique type discrimination for {@link Model} */
export const TYPE = "vested-benefit-closure";

/** Validation schema for {@link Model} */
export const schema = createSchema(TYPE, {
	fkVestedBenefitClosure: vestedBenefitClosureModelSchema.shape._id,
});
/** Movement Group representation of a {@link vestedBenefitClosureModelSchema} */
export type Model = z.infer<typeof schema>;

/** Validation schema for {@link Dto} */
export const dtoSchema = z.object({
	...schema.shape,
	vestedBenefitClosure: z.lazy(() => vestedBenefitClosureDtoSchema),
});
/**
 * Dto extension of {@link Model}.
 * Principally for query/filter purposes.
 */
export type Dto = z.infer<typeof dtoSchema>;
