import * as dateFns from "date-fns";

import { Entry } from "../../..";
import { PlanDto } from "../../../plan/dtos";
import * as Utils from "../../utils";
import { RecipeDto } from "../dtos";

/**
 * Indicate if the recipe is future or not, based on the most recent salary
 * Specific to Recipe: we consider a recipe as future if the oldest salary is after the END of the plan year
 *
 * @param plan the plan (to get the year)
 * @param recipe the recipe (to get the salaries)
 * @returns true if the recipe is future, false otherwise
 */
export function isFuture(plan: PlanDto, recipe: RecipeDto) {
	const salary = Entry.Utils.getCloserToDate(recipe.salaries, "dateStart");
	if (salary === null) {
		return false;
	}

	return dateFns.isAfter(
		salary.dateStart,
		Utils.date.fromYear(plan.year, false),
	);
}
